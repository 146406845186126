import React, { useEffect, useRef, useState } from 'react';
import { YouTubePlayer } from "@app/landing-page/social-media-embeds";
import { LicenseInfoSection } from "@common/billing/pricing-table/license-info";
import { ContactSection } from "@common/billing/pricing-table/pricing-layout-page";
import { LayoutPricing } from "@common/billing/pricing-table/pricing-layout-table";
import { CheckIcon } from '@heroicons/react/20/solid';
import { Navbar } from '@common/ui/navigation/navbar/navbar';
import { NavbarNavigation } from "@app/web-player/layout/navbar-navigation";
import { Footer } from "@app/web-player/layout/footer";
import { useProducts } from '@common/billing/pricing-table/use-products';
import { useAuth } from '@common/auth/use-auth';
import { TestHomePageCompanies, TestHomePageWhyUs, TestHomePageFeatures, TestHomePageTestimonials, TestHomePagePlatforms } from '@app/landing-page/test-pricing';


import {
    findBestPrice,
    UpsellBillingCycle,
} from '@common/billing/pricing-table/find-best-price';



import { AudiotrackIcon } from '@common/icons/material/Audiotrack';
import { FeedbackIcon } from '@common/icons/material/Feedback';
import { AutoAwesomeIcon } from '@common/icons/material/AutoAwesome';
import { GroupsIcon } from '@common/icons/material/Groups';
import { MicIcon } from '@common/icons/material/Mic';
import { SupportIcon } from '@common/icons/material/Support';
import { TextSnippetIcon } from '@common/icons/material/TextSnippet';
import { Link } from 'react-router-dom';

import pricing from './pricing-page-images/pricing.png'
import { ProgressCircle } from '@common/ui/progress/progress-circle';
import { JSX } from 'react/jsx-runtime';
import { CampaignIcon } from '@common/icons/material/Campaign';
import { TrustpilotCarousel } from './components/trustpilot-carousel';

export function FreeTrialIntroSection({ onPlansClick }: { onPlansClick: any }) {

    return (
        <div className="relative isolate overflow-hidden">
            <div className="absolute inset-0 opacity-50 mix-blend-overlay">
                {/* <img
                        src={spotify}
                        alt=""
                        loading="lazy"
                        className="absolute aspect-[6/5] h-full w-screen object-cover xl:row-span-2 xl:row-end-2"
                    /> */}
            </div>
            <div className="mx-auto max-w-5xl pt-2 pb-2 sm:pb-32 lg:flex lg:py-24 drop-shadow-lg">
                <div className="max-w-2xl px-20 flex-shrink-0 lg:max-w-xl lg:pt-8">
                    <div className="pt-24 sm:pt-48" />
                    <h1 className="mt-2 pt-2 lg:pt-12 text-4xl font-bold tracking-tight text-white sm:text-6xl">
                        Save on Beats, Promotion & artist tools with a Beatsora Membership!
                    </h1>


                    <div className="pt-24 flex flex-wrap items-center gap-x-6">
                        <a
                            href=""
                            onClick={(e) => {
                                e.preventDefault();
                                document.getElementById("promotion-packages")?.scrollIntoView({
                                    behavior: "smooth",
                                    block: "start",
                                });
                            }}
                            className="rounded-md max-w-[250px] xxs:max-w-none bg-[#1DB954] px-10 py-6 text-md xxs:text-lg font-semibold text-white shadow-sm hover:bg-[#1DB954] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                        >
                            Select your Plan
                        </a>
                    </div>



                </div>

                {/* YouTube Video Embed */}
                <div className="mx-auto mt-80 max-w-xl sm:mt-40 lg:ml-10 lg:mr-0 lg:max-w-none lg:flex-none xl:ml-32 lg:w-1/2" style={{ marginTop: '100px' }}>
                    <div className="w-full h-full rounded-2xl overflow-hidden shadow-lg">
                        <YouTubePlayer videoId="a0wl3XPw54k" />
                    </div>
                </div>

            </div>
        </div>
    )
}

const subscriptionFeatures = [
    {
        id: 1,
        name: "Beats",
        icon: AudiotrackIcon,
        href: "/explore",
    },
    {
        id: 2,
        name: "AI Mastering",
        icon: AutoAwesomeIcon,
        href: "/create-aimastering",
    },
    {
        id: 3,
        name: "AI Lyric Generator",
        icon: TextSnippetIcon,
        href: "/ai-lyrics",
    },
    {
        id: 4,
        name: "Track Feedback",
        icon: FeedbackIcon,
        href: "/feedback-form",
    },
    {
        id: 5,
        name: "Vocal Presets",
        icon: MicIcon,
        href: "/vocal-presets",
    },
    {
        id: 6,
        name: "Promotion",
        icon: CampaignIcon,
        href: "/promotion",
    },


]

export function SubscriptionFeatures({ features, title }: { features: any, title: string }) {
    return (
        <>
            <div className="pt-8 sm:pt-20 max-w-7xl mx-auto">
                <div className="flex flex-col items-center">
                    <div className="text-2xl pt-24 pb-24 text-center mx-auto font-bold tracking-tight text-white sm:text-3xl max-w-xl">
                        {title}
                    </div>


                    <div className='grid grid-cols-3 sm:grid-cols-3 lg:grid-cols-6 gap-4 mx-auto max-w-5xl pb-20 px-5'>
                        {features.map((feature: any) =>
                            <a href={feature.href} target="_blank" className="flex flex-col justify-center items-center bg-white/10 rounded-md h-auto w-full aspect-square">
                                <div className="flex flex-col justify-center items-center px-4 py-4 sm:px-16 sm:py-16">
                                    <feature.icon className='text-danger w-28 h-28 sm:w-50 sm:h-50' />
                                    <div className="text-sm sm:text-xl font-semibold text-center mt-3 sm:mt-8">{feature.name}</div>
                                </div>
                            </a>
                        )}
                    </div>
                    {/** 
                    <ul
                        role="list"
                        className="text-white text-md sm:text-lg leading-6 mx-auto pb-36"
                    >
                        {subscriptionFeatures.map((feature) => (
                            <li key={feature} className="flex gap-x-4 py-2 items-center">
                                <CheckIcon
                                    className="text-danger h-18 w-15 flex-none"
                                    aria-hidden="true"
                                />
                                {feature}
                            </li>
                        ))}
                    </ul>
                    */}
                </div>
            </div>
        </>
    )
}
export function LastSection() {
    return (
        <>
            <div className="mx-auto max-w-3xl pb-48 ">
                <p className="text-3xl pt-48 pb-24 text-center mx-auto font-bold tracking-tight text-white sm:text-4xl">Why Only Exclusive Beats?
                </p>
                <div className="mx-auto divide-y divide-white/40 px-20">
                    <p className="text-xs pt-48 pb-24 text-center mx-auto font-bold tracking-tight text-white sm:text-xl">An exclusive license ensures full control, unlimited use, and a distinct sound, which aligns with our company values of originality and creative ownership A lease license limits usage, distribution, and commercial rights,. It may also expire or become unavailable if sold exclusively, disrupting projects and reducing uniqueness.</p>



                </div>
            </div>


            <div className="mx-auto max-w-4xl text-center pb-16 mt-2 text-2xl font-bold tracking-tight text-white">
                Can't find what you're looking for? Check out our help center.
            </div>
            <a href="/helpcentre" className='mx-auto w-180 align-middle h-48 bg-danger hover:bg-dangerlighter  text-white shadow-sm mt-8 block rounded-md py-4 px-3 text-center text-lg font-semibold leading-6'>
                <div className='pt-8'>
                    Help Center
                </div>
            </a>

            <div className='pt-48' />


            {/**
        <div className="p-24 text-center my-20 md:my-80">
            <ForumIcon size="xl" className="text-muted" />
            <div className="md:text-lg my-8">
                <Trans message="Do you have any questions about PRO accounts?" />
            </div>
            <div className="mb-24 text-sm md:text-base mt-20 md:mt-0">
                <Trans message="Our support team will be happy to assist you." />
            </div>
            <Button variant="flat" color="danger" elementType={Link} to="/contact">
                <Trans message="Contact us" />
            </Button>
        </div>
         */}
        </>
    );
}
export function FreeTrialPageLayout() {
    const layoutPricingRef = useRef(null);
    const [pageLoading, setPageLoading] = useState(true);

    useEffect(() => {
        // Set a timeout to stop loading after 1 second
        const timer = setTimeout(() => {
            setPageLoading(false);
        }, 200);

        // Cleanup the timer if the component unmounts
        return () => clearTimeout(timer);
    }, []);

    if (pageLoading) {
        return (
            <div className="flex justify-center items-center mx-auto">
                <ProgressCircle
                    size="lg"
                    isIndeterminate
                />
            </div>
        );
    }
    const handlePlansClick = () => {
        layoutPricingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <>
            <div className="main-layout-wrapper">
                <FreeTrialIntroSection onPlansClick={handlePlansClick} />
                <div className="bg-black/0">
                    <SubscriptionFeatures features={subscriptionFeatures} title="What's included in Beatsora?" />
                    <TrustpilotCarousel />
                    <TestHomePageCompanies bgColour="bg-gradient-to-b from-black to-black" marqueeClassname='' />
                    <TestHomePageFeatures bgColour="bg-gradient-to-b from-black to-black " />
                    <TestHomePageTestimonials bgColour="bg-gradient-to-b from-black to-black " />
                    <TestHomePagePlatforms bgColour="bg-gradient-to-b from-black to-white/30 " />

                    <div ref={layoutPricingRef} id="layoutPricingSection">
                        <LayoutPricing />
                    </div>
                    <LicenseInfoSection />
                    <LastSection />
                </div>
            </div>
        </>
    );

}
