import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import axios from 'axios';

export function CartIcon() {
    const [cartCount, setCartCount] = useState(0);

    useEffect(() => {
        // Fetch cart data to get count
        const fetchCartCount = async () => {
            try {
                const response = await axios.get('/api/cart');
                if (response.data.success) {
                    const count = Object.keys(response.data.cart).length;
                    setCartCount(count);
                }
            } catch (error) {
                console.error('Error fetching cart:', error);
            }
        };

        fetchCartCount();

        // Set up interval to refresh cart count
        const intervalId = setInterval(fetchCartCount, 30000); // Update every 30 seconds

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="relative">
            <ShoppingCartIcon className="h-20 w-20 text-white" />
            {cartCount > 0 && (
                <span className="absolute -top-2 -right-2 bg-danger text-white text-xs font-bold rounded-full w-12 h-12 flex items-center justify-center">
                    {cartCount > 9 ? '9+' : cartCount}
                </span>
            )}
        </div>
    );
}