import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

// Initialize Stripe
const stripePromise = loadStripe('pk_test_51POdgYLgPVZjcxHhaEbDq71YvfqscQY6xwddxO7tnChtfM0sPJYRzej6zDNRa98kc0QUTuD1j5wIZDeyRXKWEZKA00BZZdPnvJ');

interface CartItem {
	id: string;
	type: string;
	name: string;
	price: number;
	image: string;
	package?: {
		name: string;
		price: string;
		stream: string;
		href: string;
	};
	metadata: any;
}

interface CheckoutFormProps {
	cart: Record<string, any>;
	subtotal: number;
	discount: number;
	total: number;
	promoDiscount: number;
	promoCode: string;
}

function CheckoutForm({ cart, subtotal, discount, total, promoDiscount, promoCode }: CheckoutFormProps) {
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [processing, setProcessing] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [succeeded, setSucceeded] = useState(false);
	const [billingAddress, setBillingAddress] = useState({
		line1: '',
		city: '',
		state: '',
		postal_code: ''
	});
	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		setProcessing(true);
		setError(null);

		const cardElement = elements.getElement(CardElement);
		if (!cardElement) {
			setError("Card element not found");
			setProcessing(false);
			return;
		}

		try {
			const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
				type: 'card',
				card: cardElement,
				billing_details: {
					name: `${firstName} ${lastName}`,
					email: email,
					address: {
						line1: billingAddress.line1,
						city: billingAddress.city,
						state: billingAddress.state,
						postal_code: billingAddress.postal_code
					}
				},
			});

			if (stripeError) {
				setError(stripeError.message || "Payment method creation failed");
				setProcessing(false);
				return;
			}

			if (!billingAddress.line1 || !billingAddress.city || !billingAddress.state || !billingAddress.postal_code) {
				setError('Please fill in all billing address fields');
				setProcessing(false);
				return;
			}

			// Send data to the server including promoDiscount if applicable
			const response = await axios.post('/api/cart-checkout-process', {
				paymentMethodId: paymentMethod?.id,
				email,
				firstName,
				lastName,
				cart,
				total,
				billingAddress,
				promoDiscount,
				promoCode
			});

			if (response.data.success) {
				setSucceeded(true);
			} else {
				setError('Payment processing failed');
			}
		} catch (err) {
			console.error('Error processing payment:', err);
			setError('There was an error processing your payment');
		} finally {
			setProcessing(false);
		}
	};

	const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setBillingAddress(prev => ({
			...prev,
			[name]: value
		}));
	};

	return (
		<form onSubmit={handleSubmit}>
			<div className="mb-8 space-y-4">
				<span className="text-xl font-light tracking-tighter text-white">Contact</span>
				<div className="space-y-4">
					<div className="grid grid-cols-1">
						<label className="font-normal text-white">
							Email <span className="text-white/50">- We'll send you a receipt</span>
						</label>
						<div className="flex flex-col">
							<input
								className="w-full rounded-lg bg-gray-800 px-4 py-2 text-white border border-gray-700 focus:border-danger focus:outline-none"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								name="email"
								type="email"
								required
							/>
							<span className="mt-1 text-sm text-white/80">
								Your order details will be sent to this email.
							</span>
						</div>
					</div>
					<div className="grid grid-cols-2 gap-x-3">
						<div className="w-full flex flex-col">
							<label htmlFor="First name" className="text-white font-normal">
								First name
							</label>
							<input
								id="First name"
								className="p-3 rounded-lg bg-gray-800 px-4 py-2 text-white border border-gray-700 focus:border-danger focus:outline-none"
								type="text"
								value={firstName}
								onChange={(e) => setFirstName(e.target.value)}
								name="firstName"
								required
							/>
						</div>
						<div className="w-full flex flex-col">
							<label htmlFor="Last name" className="text-white font-normal">
								Last name
							</label>
							<input
								id="Last name"
								className="p-3 rounded-lg bg-gray-800 px-4 py-2 text-white border border-gray-700 focus:border-danger focus:outline-none"
								type="text"
								value={lastName}
								onChange={(e) => setLastName(e.target.value)}
								name="lastName"
								required
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="mb-8 space-y-4">
				<span className="text-xl font-light tracking-tighter text-white">Billing Address</span>
				<div className="space-y-4">
					<div className="grid grid-cols-1">
						<input
							className="w-full rounded-lg bg-gray-800 px-4 py-2 text-white border border-gray-700 focus:border-danger focus:outline-none"
							placeholder="Street Address"
							name="line1"
							value={billingAddress.line1}
							onChange={handleAddressChange}
							required
						/>
					</div>
					<div className="grid grid-cols-3 gap-3">
						<input
							className="rounded-lg bg-gray-800 px-4 py-2 text-white border border-gray-700 focus:border-danger focus:outline-none"
							placeholder="City"
							name="city"
							value={billingAddress.city}
							onChange={handleAddressChange}
							required
						/>
						<input
							className="rounded-lg bg-gray-800 px-4 py-2 text-white border border-gray-700 focus:border-danger focus:outline-none"
							placeholder="State"
							name="state"
							value={billingAddress.state}
							onChange={handleAddressChange}
							required
						/>
						<input
							className="rounded-lg bg-gray-800 px-4 py-2 text-white border border-gray-700 focus:border-danger focus:outline-none"
							placeholder="ZIP Code"
							name="postal_code"
							value={billingAddress.postal_code}
							onChange={handleAddressChange}
							required
						/>
					</div>
				</div>
			</div>
			<div className="w-full">
				<div className="space-y-4">
					<div className="flex flex-col">
						<span className="text-xl font-light tracking-tighter text-white">Payment</span>
						<span className="text-sm text-white/50">
							All transactions are secure and encrypted.
						</span>
					</div>
					<div className="bg-black/50 border border-gray-700 rounded-lg p-4">
						<CardElement
							options={{
								style: {
									base: {
										fontSize: '16px',
										color: '#ffffff',
										'::placeholder': {
											color: '#aab7c4',
										},
									},
									invalid: {
										color: '#fa755a',
									},
								},
							}}
						/>
					</div>
					{error && <div className="text-red-500 text-sm">{error}</div>}
					{succeeded && <div className="text-green-500 text-sm">Payment successful!</div>}
					<div className="flex translate-y-1 flex-row-reverse items-center justify-between gap-x-2 mt-4">
						<button
							type="submit"
							disabled={!stripe || processing}
							className="block rounded-lg w-full bg-danger px-4 py-3 text-lg font-bold text-white transition-all ease-out hover:bg-dangerlighter disabled:opacity-50 md:w-[125px]"
						>
							{processing ? 'Processing...' : 'Pay now'}
						</button>
					</div>
				</div>
			</div>
		</form>
	);
}

export function CartCheckoutPage() {
	const [cart, setCart] = useState<Record<string, CartItem>>({});
	const [loading, setLoading] = useState(true);
	const [showSummary, setShowSummary] = useState(true);
	const [addOns, setAddOns] = useState<{ [key: string]: boolean }>({
		campaignUpgrade: false,
		consultationUpgrade: false,
	});

	// New state for promo code and discount amount
	const [promoCode, setPromoCode] = useState('');
	const [promoDiscount, setPromoDiscount] = useState(0);

	useEffect(() => {
		const fetchCart = async () => {
			try {
				const response = await axios.get('/api/cart');
				if (response.data.success) {
					setCart(response.data.cart);
				}
			} catch (error) {
				console.error('Error fetching cart:', error);
			} finally {
				setLoading(false);
			}
		};
		fetchCart();
	}, []);

	const removeFromCart = async (cartItemId: string) => {
		try {
			console.log("Removing item:", cartItemId);
			const updatedCart = { ...cart };
			delete updatedCart[cartItemId];
			setCart(updatedCart);
			await axios.post('/api/cart-remove', { cart_item_id: cartItemId });
		} catch (error) {
			console.error('Error removing from cart:', error);
			const response = await axios.get('/api/cart');
			if (response.data.success) {
				setCart(response.data.cart);
			}
		}
	};

	const addAddonToCart = async (addon: any) => {
		try {
			console.log("Adding addon:", addon.id);
			const addonItem = {
				id: `addon-${Date.now()}`,
				type: 'addon',
				name: addon.name,
				price: parseFloat(addon.price.replace('$', '')),
				image: 'https://placehold.co/200x200/black/white?text=Addon',
				metadata: { description: addon.description }
			};
			const tempId = `temp-${Date.now()}`;
			setCart(prevCart => ({ ...prevCart, [tempId]: addonItem }));
			if (addon.id === 'campaign-upgrade') {
				setAddOns(prev => ({ ...prev, campaignUpgrade: true }));
			} else if (addon.id === 'consultation') {
				setAddOns(prev => ({ ...prev, consultationUpgrade: true }));
			}
			await axios.post('/api/cart-add', addonItem);
		} catch (error) {
			console.error('Error adding addon to cart:', error);
		}
	};

	const calculateSubtotal = () => {
		return Object.values(cart).reduce((sum, item) => sum + item.price, 0);
	};

	const calculateDiscount = () => {
		const promotionItems = Object.values(cart).filter(item => item.type === 'promotion');
		if (promotionItems.length > 1) {
			const additionalSongsTotal = promotionItems.slice(1).reduce((sum, item) => sum + item.price, 0);
			return additionalSongsTotal * 0.1;
		}
		return 0;
	};

	const calculateTotal = () => {
		return calculateSubtotal() - calculateDiscount() - promoDiscount;
	};

	// Handler for applying a promo code
	const applyPromoCode = async () => {
		try {
			const response = await axios.post('/api/validate-promo', {
				promoCode: promoCode.trim().toUpperCase()
			});
	
			if (response.data > 0) {
				// Assuming the API returns the discount amount directly
				setPromoDiscount(response.data);
			} else {
				setPromoDiscount(0);
				alert('Invalid promo code');
			}
		} catch (error) {
			console.error('Error validating promo code:', error);
			setPromoDiscount(0);
			alert('Error validating promo code');
		}
	};	

	const availableAddons = [
		{
			id: 'campaign-upgrade',
			name: 'Campaign upgrade',
			description: 'Pitch to 2x more playlists and stay on playlists 2x longer',
			originalPrice: '$225',
			price: '$37.99',
			discount: '80% OFF',
			features: [
				'Pitch to 2x more playlists ($150 value)',
				'Stay on playlists 2x longer ($100 value)',
				'Priority placements in 24 hours'
			]
		},
		{
			id: 'consultation',
			name: '15 Minutes Consultation',
			description: '15 Minutes Consultation',
			originalPrice: '$99',
			price: '$29.99',
			discount: '70% OFF',
			features: [
				'Direct contact details for 7,000+ curators',
				'Organized by genre and size',
				'Regular updates with new contacts'
			]
		},
	];

	if (loading) {
		return (
			<div className="flex justify-center items-center h-screen bg-black">
				<div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-danger"></div>
			</div>
		);
	}

	return (
		<div className="bg-black">
			<div className="flex w-full flex-col-reverse md:flex-row">
				{/* Left side - Payment form */}
				<div className="relative w-screen overflow-y-auto px-4 pb-24 pt-10 md:w-[56.75%] md:max-h-screen md:pl-16 md:pr-16 md:pt-24">
					<div className="float-none w-full max-w-[532px] max-md:mx-auto md:float-right">
						<div>
							<Elements stripe={stripePromise}>
								<CheckoutForm
									cart={cart}
									subtotal={calculateSubtotal()}
									discount={calculateDiscount()}
									total={calculateTotal()}
									promoDiscount={promoDiscount}
									promoCode={promoCode}
								/>
							</Elements>
							{/* Testimonials */}
							<div className="flex flex-col py-8">
								<h1 className="text-lg font-bold py-4 text-white">Top artists win with us:</h1>
								<div className="flex flex-row w-full py-6 gap-2">
									{/* Testimonials can be added here */}
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* Right side - Cart summary */}
				<div className="relative w-screen overflow-y-auto bg-gray-900 pt-20 md:h-screen md:max-h-screen md:w-[43.25%] md:pb-24 md:pl-11 md:pr-16 md:pt-24">
					<div className="w-full md:max-w-[418px]">
						<button
							className="w-full bg-gray-800 p-4 md:hidden"
							onClick={() => setShowSummary(!showSummary)}
						>
							<div className="mx-auto flex max-w-[532px] flex-row items-center justify-between">
								<div className="flex items-center gap-x-2 text-sm text-white hover:cursor-pointer">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
										<path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
									</svg>
									<div className="flex items-center gap-x-1">
										{showSummary ? 'Hide' : 'Show'} order summary
									</div>
								</div>
								<div className="flex flex-col items-end -space-y-1">
									<span className="text-sm text-gray-400 line-through">
										${calculateSubtotal().toFixed(2)}
									</span>
									<div className="flex items-center gap-x-1">
										<p className="text-sm font-light tracking-wide text-danger">USD</p>
										<span className="text-xl font-bold text-white">${calculateTotal().toFixed(2)}</span>
									</div>
								</div>
							</div>
						</button>
						<div className={`${showSummary ? 'block' : 'hidden'} md:block`}>
							<div className="relative">
								<div className="w-full space-y-3">
									{Object.entries(cart).length === 0 ? (
										<div className="text-white text-center py-6">Your cart is empty</div>
									) : (
										Object.entries(cart).map(([cartItemId, item]) => {
											const isAdditionalPromotion =
												item.type === 'promotion' &&
												Object.values(cart).filter(i => i.type === 'promotion').findIndex(i => i === item) > 0;
											return (
												<div key={cartItemId} style={{ opacity: 1 }}>
													<div className="rounded-lg relative flex h-[75px] w-full flex-row items-center text-white transition-all">
														<div className="relative h-[75px] w-[75px] flex-shrink-0 p-[0.125rem]">
															<div className="relative h-full w-full">
																<img alt="" src={item.image} className="rounded-lg h-full w-full object-cover" />
															</div>
														</div>
														<div className="relative h-full min-w-[46%] max-w-[60%] flex-grow p-1">
															<div className="relative flex h-full w-full flex-col justify-center -space-y-1 p-2 text-xl">
																<div>
																	<h2 className="text-md max-h-[120px] truncate font-bold tracking-tighter">{item.name}</h2>
																</div>
																<div className="flex justify-between">
																	<p className="text-sm text-gray-400">
																		{item.type === 'promotion'
																			? (item.package?.name || 'Promotion package')
																			: (item.type === 'addon' ? 'Add-on' : 'Standard')}
																	</p>
																</div>
															</div>
														</div>
														<div className="flex flex-col items-end -space-y-1">
															{isAdditionalPromotion ? (
																<>
																	<span className="text-sm text-gray-400 line-through">${item.price.toFixed(2)}</span>
																	<span className="text-md">${(item.price * 0.9).toFixed(2)}</span>
																</>
															) : (
																<span className="text-md">${item.price.toFixed(2)}</span>
															)}
														</div>
														<button onClick={() => removeFromCart(cartItemId)} className="absolute right-0 top-1 text-danger">
															<XMarkIcon className="h-20 w-20" />
														</button>
													</div>
												</div>
											);
										})
									)}
								</div>
								<div className="bottom-0 mt-6 space-y-3 bg-gray-900 md:sticky">
									<hr className="h-[1px] w-full border-0 bg-danger" />
									<div className="w-full h-1"></div>
									{/* Promo code / discount code section */}
									<div className="grid grid-cols-5 items-end gap-x-3">
										<div className="col-span-4">
											<input
												className="w-full p-3 text-md placeholder-gray-600 rounded-lg bg-transparent border border-gray-700"
												placeholder="Gift card or discount code"
												type="text"
												value={promoCode}
												onChange={(e) => setPromoCode(e.target.value)}
											/>
										</div>
										<button
											className="rounded-lg h-full bg-danger text-white transition-all duration-200 ease-out hover:bg-dangerlighter disabled:bg-gray-700 disabled:text-white disabled:opacity-50 p-3"
											onClick={applyPromoCode}
											type="button"
										>
											Apply
										</button>
									</div>
									<hr className="h-[1px] w-full border-0 bg-danger" />
									{/* Order summary */}
									<div className="text-md flex w-full items-center justify-between text-white">
										<h3>Subtotal</h3>
										<h3 className="text-md font-light">${calculateSubtotal().toFixed(2)}</h3>
									</div>
									{/* Discounts */}
									{calculateDiscount() > 0 && (
										<div style={{ opacity: 1 }}>
											<div className="w-full bg-transparent rounded-lg flex flex-row transition-all duration-50 text-white text-sm">
												<div className="relative flex-grow pr-6">
													<div className="relative w-full h-full flex flex-row">
														<div className="flex justify-between flex-grow">
															<p className="text-gray-400 truncate">10% off for additional songs</p>
															<p className="flex-shrink-0 w-[80px] text-right">- ${calculateDiscount().toFixed(2)}</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									)}
									{/* Promo discount display */}
									{promoDiscount > 0 && (
										<div className="text-md flex w-full items-center justify-between text-white">
											<h3>Promo discount</h3>
											<h3 className="text-md font-light">- ${promoDiscount.toFixed(2)}</h3>
										</div>
									)}
									<hr className="h-[1px] w-full border-0 bg-danger" />
									{/* Total */}
									<div className="flex w-full items-center justify-between text-xl text-white">
										<h3>Total</h3>
										<div className="flex flex-row items-center gap-x-4">
											<p className="text-sm font-light tracking-wide text-danger">USD</p>
											<h3 className="text-2xl font-light">${calculateTotal().toFixed(2)}</h3>
										</div>
									</div>
								</div>
								{/* Popular add-ons */}
								<div className="py-8">
									<div className="py-4">
										<h1 className="text-xl text-white">Popular add-ons 🔥</h1>
									</div>
									<div className="flex flex-col gap-8 py-4">
										{availableAddons.map((addon) => (
											<div key={addon.id} className="rounded-lg relative bg-gray-800 transition-transform duration-200">
												<div className="rounded-lg flex w-full flex-row items-center bg-gray-800 p-2">
													<div className="flex flex-col p-2 pr-16 w-full">
														<h3 className="py-2 text-lg font-bold text-white">
															{addon.name} (<span className="text-danger">{addon.discount}</span>)
														</h3>
														<div className="py-2">
															<div className="text-xs text-gray-400">
																{addon.features.map((feature, index) => (
																	<p key={index}>✅ {feature}</p>
																))}
															</div>
														</div>
														<div className="justify-center gap-x-1 text-sm text-white">
															for only <span className="text-sm text-gray-400 line-through">{addon.originalPrice}</span> {addon.price}
														</div>
													</div>
												</div>
												<div className="absolute right-4 top-1/2 -translate-y-1/2">
													<button
														type="button"
														className="flex h-12 w-12 items-center justify-center rounded-md bg-white cursor-pointer hover:bg-gray-200 active:bg-gray-300"
														onClick={() => {
															console.log("Add button clicked", addon.id);
															addAddonToCart(addon);
														}}
														disabled={
															(addon.id === 'campaign-upgrade' && addOns.campaignUpgrade) ||
															(addon.id === 'consultation' && addOns.consultationUpgrade)
														}
													>
														<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900">
															<path d="M5 12h14"></path>
															<path d="M12 5v14"></path>
														</svg>
													</button>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CartCheckoutPage;
