import { MicrophoneIcon, RectangleStackIcon, FilmIcon, RadioIcon, LockOpenIcon, MusicalNoteIcon } from '@heroicons/react/24/solid';

const licenseInfo = [
  {
    ability: "What your license includes:",
    list: [
      {
        name: <div>Used for music recording</div>,
        icon: MicrophoneIcon,
      },
      {
        name: <div>Distribute <b>unlimited</b> copies</div>,
        icon: RectangleStackIcon,
      },
      {
        name: <div><b>Unlimited</b> online audio streams (keep 100% royalties)</div>,
        icon: LockOpenIcon,
      },
      {
        name: <div>Radio broadcasting to <b>unlimited</b> stations</div>,
        icon: RadioIcon,
      },
      {
        name: <div><b>Unlimited</b> music video rights</div>,
        icon: FilmIcon,
      },
      {
        name: <div>For profit live performances</div>,
        icon: MusicalNoteIcon,
      },
    ],
  },
]


export function LicenseInfoDownloadConfirmation() {
    return (
        <>
          <div className="pb-12 pt-6 px-10">
            {licenseInfo.map((feature) => (
              <>
                <dl className='grid grid-cols-2 mx-auto space-y-8 items-center'>
                  {feature.list.map((listitem) => (
                    <div className='inline-flex items-center mx-auto'>
                      <listitem.icon className='text-danger w-24 h-auto pr-8' />
                      <div className='text-white text-xs w-140 pr-10'>
                        {listitem.name}
                      </div>
                    </div>
                  ))}
                </dl>
              </>
            ))}
          </div>
        </>
    )
}

export function LicenseInfoSection() {
  return (
      <>
        <div className='max-w-4xl mx-auto'>
          <div className='text-xl font-bold mx-auto text-center pb-12 xl:pb-0'>
            What's included in your license?
          </div>
          <div className="pb-12 pt-6 px-10 mx-auto">
            {licenseInfo.map((feature) => (
              <>
                <dl className='grid grid-cols-1 xl:grid-cols-2 mx-auto space-y-20 items-center'>
                  {feature.list.map((listitem) => (
                    <div className='inline-flex items-center mx-auto '>
                      <listitem.icon className='text-danger w-32 h-auto pr-8' />
                      <div className='text-white text-lg w-auto xl:w-400'>
                        {listitem.name}
                      </div>
                    </div>
                  ))}
                </dl>
              </>
            ))}
          </div>
        </div>
      </>
  )
}

