import clsx from 'clsx';
import { LandingPageContent } from './landing-page-content';
import { Navbar } from '@common/ui/navigation/navbar/navbar';
import { Button, ButtonProps } from '@common/ui/buttons/button';
import { MixedImage } from '@common/ui/images/mixed-image';
import { Trans } from '@common/i18n/trans';
import { Link } from 'react-router-dom';
import { createSvgIconFromTree } from '@common/icons/create-svg-icon';
import { MenuItemConfig } from '@common/core/settings/settings';
import { Fragment, SVGProps, useState } from 'react';
import { DefaultMetaTags } from '@common/seo/default-meta-tags';
import { useSettings } from '@common/core/settings/use-settings';
import { PricingTable } from '@common/billing/pricing-table/pricing-table';
import { BillingCycleRadio } from '@common/billing/pricing-table/billing-cycle-radio';
import { UpsellBillingCycle } from '@common/billing/pricing-table/find-best-price';
import { useProducts } from '@common/billing/pricing-table/use-products';
import { TextField } from '@common/ui/forms/input-field/text-field/text-field';
import { SearchIcon } from '@common/icons/material/Search';
import { useNavigate } from '@common/utils/hooks/use-navigate';
import { useLandingPageTrendingArtists } from '@app/landing-page/requests/use-landing-page-trending-artists';
import { ContentGrid } from '@app/web-player/playable-item/content-grid';
import { SmallArtistImage } from '@app/web-player/artists/artist-image/small-artist-image';
import { ArtistLink, getArtistLink } from '@app/web-player/artists/artist-link';
import { PlayableMediaGridSkeleton } from '@app/web-player/playable-item/player-media-grid-skeleton';
import { useTrans } from '@common/i18n/use-trans';
import { message } from '@common/i18n/message';
import { useLightThemeVariables } from '@common/ui/themes/use-light-theme-variables';

import YouTube from 'react-youtube';
import Image from 'next/image'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import logo3D from "./images/intro/beatsora3d2.png"
import background from "./images/intro/backgroundswirls.png"
import betalogo from "./images/intro/beatsora logo white compressed.png"
import Marquee from "react-fast-marquee";
import cineworldLogo from './images/companies/cineworldLogo.png'
import commonwealthLogo from './images/companies/commonwealthLogo.png'
import eaLogo from './images/companies/eaLogo.png'
import fifaLogo from './images/companies/fifaLogo.png'
import loveislandLogo from './images/companies/loveislandLogo.png'
import pepsiLogo from './images/companies/pepsiLogo.png'
import primevideoLogo from './images/companies/primevideoLogo.png'
import sonyLogo from './images/companies/sonyLogo.png'
import { Disclosure, RadioGroup } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import { CheckIcon, XMarkIcon, ArrowDownCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import amazon from './images/platforms/amazon.png'
import apple from './images/platforms/apple.png'
import deezer from './images/platforms/deezer.png'
import facebook from './images/platforms/facebook.png'
import instagram from './images/platforms/instagram.png'
import pandora from './images/platforms/pandora.png'
import shazam from './images/platforms/shazam.png'
import spotify from './images/platforms/spotify.png'
import tidal from './images/platforms/tidal.png'
import tiktok from './images/platforms/tiktok.png'
import twitter from './images/platforms/twitter.png'
import youtube from './images/platforms/youtube.png'
import musicianPicture from './images/sampleimagemusicians.png'
import explainationIcon1 from './images/explaination-images/beatsoraexplainationicon1.png'
import explainationIcon2 from './images/explaination-images/beatsoraexplainationicon2.png'
import explainationIcon3 from './images/explaination-images/beatsoraexplainationicon3.png'
import explainationIcon4 from './images/explaination-images/beatsoraexplainationicon4.png'
import explainationIcon5 from './images/explaination-images/beatsoraexplainationicon5.png'
import { TileTextLeft, TileTextRight, TextSectionLeft, TextSectionRight } from './image-text-tile'
import tile1 from './images/tile-images/tile1.png'
import tile2 from './images/tile-images/tile2.png'
import tile3 from './images/tile-images/tile3.png'
import tile4 from './images/tile-images/tile4.png'
import tile5 from './images/tile-images/tile5.png'
import tile6 from './images/tile-images/tile6.png'
import { Tooltip } from 'flowbite-react'
import { useRef } from 'react';
import { HomePagePricing } from './pricing-section'
import { BetaBannerModal } from './beta-banner-modal';
import testimonialimage1 from './images/testimonials/testimonial1.png'
import testimonialimage2 from './images/testimonials/testimonial2.png'
import testimonialimage3 from './images/testimonials/testimonial3.png'
import testimonialimage4 from './images/testimonials/testimonial4.png'
import testimonialimage5 from './images/testimonials/testimonial5.png'
import afrobeatCollectionImage from './images/collection-images/afrobeat_collection.png'
import drillCollectionImage from './images/collection-images/drill_collection.png'
import hiphopCollectionImage from './images/collection-images/hiphop_collection.png'
import trapCollectionImage from './images/collection-images/trap_collection.png'
import chillhopCollectionImage from './images/collection-images/chillhop_collection.png'
import melodicdrillCollectionImage from './images/collection-images/melodicdrill_collection.png'
import oldschoolCollectionImage from './images/collection-images/oldschool_collection.png'
import { Footer } from '@app/web-player/layout/footer';
import { TikTokEmbed } from 'react-social-media-embed';
import { searchPlaceholder } from './search-placeholder';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid'
import backgroundImage from './images/intro/beatsoraunleashed.png'
import ethosImage1 from './images/ethos-images/ethosimage1.png'
import { AnimatedBackground } from './animated-background';
import { playerStoreOptions } from '@app/landing-page/state/player-store-options';
import { PlayerContext } from '@app/landing-page/com/player/player-context';
import { DesktopPlayerControls } from '@app/landing-page/player-controls/desktop-player-controls';
import { PlayerOverlay } from '@app/landing-page/overlay/player-overlay';
import { YouTubePlayer } from './social-media-embeds';
import { NavbarNavigation } from '@app/web-player/layout/navbar-navigation';
import { SubscriptionFeatures } from '@common/footer-pages/free-trial-page';
import { AudiotrackIcon } from '@common/icons/material/Audiotrack';
import { FeedbackIcon } from '@common/icons/material/Feedback';
import { AutoAwesomeIcon } from '@common/icons/material/AutoAwesome';
import { GroupsIcon } from '@common/icons/material/Groups';
import { MicIcon } from '@common/icons/material/Mic';
import { SupportIcon } from '@common/icons/material/Support';
import { TextSnippetIcon } from '@common/icons/material/TextSnippet';
import { CampaignIcon } from '@common/icons/material/Campaign';
import { TrustpilotCarousel } from '@common/footer-pages/components/trustpilot-carousel';
import trustpilot from "@common/footer-pages/trustpilot/trustpilot.png"

interface LandingPageProps {
  landingType?: string;
}

interface ContentProps {
  content: LandingPageContent;
  landingType: string;
}
export function LandingPage({ landingType = "landing" }: LandingPageProps) {
  const settings = useSettings();
  const appearance = settings.homepage.appearance;
  const showPricing = settings.homepage?.pricing && settings.billing.enable;
  const showTrending = settings.homepage?.trending;
  const urlParams = new URLSearchParams(window.location.search);

  const landingPageTypes = ['landing', 'conversion'];

  if (urlParams.has('landingType')) {
    const landingTypePar = urlParams.get('landingType');
    if (landingPageTypes.includes(landingTypePar)) {
      landingType = landingTypePar;
    }


  }

  return (
    <Fragment>
      <DefaultMetaTags />
      <HeroHeader content={appearance} landingType={landingType} />
    </Fragment>
  );
}
interface HomePageIntroProps {
  navigate: (path: string) => void;

}
function HeroHeader({ content, landingType }: ContentProps) {
  const lightThemeVars = useLightThemeVariables();
  const { trans } = useTrans();
  const navigate = useNavigate();
  const ref = useRef<null | HTMLDivElement>(null);

  const pricingHandleClick = () => {
    // ref.current?.scrollIntoView({ behavior: 'smooth' });
    window.location.href = "#pricing-tbl";
  };

  const {
    headerTitle,
    headerSubtitle,
    headerImage,
    headerImageOpacity,
    actions,
    headerOverlayColor1,
    headerOverlayColor2,
  } = content;
  let overlayBackground = undefined;

  if (headerOverlayColor1 && headerOverlayColor2) {
    overlayBackground = `linear-gradient(45deg, ${headerOverlayColor1} 0%, ${headerOverlayColor2} 100%)`;
  } else if (headerOverlayColor1) {
    overlayBackground = headerOverlayColor1;
  } else if (headerOverlayColor2) {
    overlayBackground = headerOverlayColor2;
  }

  // Get the current URL
  const urlParams = new URLSearchParams(window.location.search);

  // Check if the "via" parameter exists and has a value
  if (urlParams.has('via')) {
    const viaValue = urlParams.get('via');
    // console.log('viaValue',window.promotekit_referral)
    if (viaValue !== null) {
      localStorage.setItem('via_promotekit', window.promotekit_referral);
      try {
        const response = fetch('/api/set-affiliate-code', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ your_affiliate_code: window.promotekit_referral }),
        });
      } catch (error) {
        // console.log('error')
      }
    }
  }

  return (
    <header className="relative isolate">
      <div className="flex flex-col relative h-full">
        <Navbar
          color="transparent"
          darkModeColor="transparent"
          className="flex-shrink-0"
          menuPosition="landing-page-navbar"
          primaryButtonColor="white"
        >
          <NavbarNavigation page='landing' />
        </Navbar>
        {/* <BetaBannerModal /> */}

        {landingType == "landing" &&
          <>
            <HomePageIntro navigate={navigate} pricingHandleClick={pricingHandleClick} />
            <div className='bg-black'>
              <TrustpilotCarousel />
              <SubscriptionFeatures features={subscriptionFeatures} title="What's included in Beatsora?" />
            </div>
            {/*<HomePageWhyUs bgColour="bg-black" />*/}
            <HomePageCompanies bgColour="bg-gradient-to-b from-black to-black" marqueeClassname='' />
            {/*<HomePageAudio bgColour="bg-gradient-to-b from-black to-black" />*/}
            <HomePageFeatures bgColour="bg-gradient-to-b from-black to-black " />
            <HomePageTestimonials bgColour="bg-gradient-to-b from-black to-black " />
            <HomePagePlatforms bgColour="bg-gradient-to-b from-black to-white/30 " />
            <div ref={ref}>
              <div className="isolate overflow-hidden">
                <div className="flow-root bg-gradient-to-b from-white/30 to-black pb-16 pt-24 sm:pt-48 lg:pb-0">
                  <HomePagePricing />
                </div>
              </div>
            </div>
            <HomePageSocials bgColour="bg-gradient-to-b from-black to-black" />
            <HomePageFAQ bgColour="bg-gradient-to-b from-black to-black/0" />
          </>
        }
        {/**

        {landingType == "conversion" &&
          <>
            <HomePageConversionIntro bgColour="bg-gradient-to-b from-danger/70 to-black " />
            <HomePageCompanies bgColour="bg-black" marqueeClassname='' />
            <HomePageAudio bgColour="bg-black" />
            <HomePageFeatures bgColour="bg-gradient-to-b from-black to-danger/70 " />
            <HomePageTestimonials bgColour="bg-gradient-to-b from-danger/70 to-black " />
            <HomePageSocials bgColour="bg-gradient-to-b from-black to-danger/70" />
            <HomePageFAQ bgColour="bg-gradient-to-b from-danger/70 to-white/0" />
          </>
        }

         */}
        <Footer />
        <div className='mb-14 md:mb-60 bg' />
      </div>
    </header>
  );
}


// Sections from Mar's site - START
const subscriptionFeatures = [
  {
    id: 1,
    name: "Beats",
    icon: AudiotrackIcon,
    href: "/explore",
  },
  {
    id: 2,
    name: "AI Mastering",
    icon: AutoAwesomeIcon,
    href: "/create-aimastering",
  },
  {
    id: 3,
    name: "AI Lyric Generator",
    icon: TextSnippetIcon,
    href: "/ai-lyrics",
  },
  {
    id: 4,
    name: "Track Feedback",
    icon: FeedbackIcon,
    href: "/feedback-form",
  },
  {
    id: 5,
    name: "Vocal Presets",
    icon: MicIcon,
    href: "/vocal-presets",
  },
  {
    id: 6,
    name: "Promotion",
    icon: CampaignIcon,
    href: "/promotion",
  },


]

function HomePageIntro({ navigate, pricingHandleClick }: { navigate: any, pricingHandleClick: any }) {

  return (
    <div className="relative isolate overflow-hidden bg-gradient-to-r from-dangerdarker to-dangerlighter">
      <div className='absolute inset-0 opacity-50 mix-blend-overlay'>
        <img
          src={backgroundImage}
          alt=""
          loading='lazy'
          className="absolute aspect-[6/5] h-full w-screen object-cover xl:row-span-2 xl:row-end-2"
        />

      </div>
      <div className="mx-auto max-w-5xl pt-2 pb-2 sm:pb-32 lg:flex lg:py-24 drop-shadow-lg">


        <div className="max-w-2xl px-20 flex-shrink-0 lg:max-w-xl lg:pt-8 ">

          <div className="mt-8 lg:mt-2">

          </div>
          <div className='pt-24 sm:pt-48' />
          <h1 className="mt-2 pt-2 lg:pt-12 text-4xl font-bold tracking-tight text-white sm:text-6xl">
            100% Exclusive Instrumentals and Organic Spotify Promotion
          </h1>
          <p className="pt-12 text-xl leading-8 text-white ">
            The go-to place for musicians who are serious about growing their career the right way
          </p>
          <div className="pt-12 flex items-center gap-x-6 flex-wrap">
            <a
              href="./explore"
              className="rounded-md max-w-[150px] xxs:max-w-none bg-black px-10 py-6 text-md xxs:text-lg font-semibold text-white shadow-sm hover:bg-dangerlighter focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Instrumentals
            </a>
            <a
              href="./promotion"
              className="rounded-md max-w-[250px] xxs:max-w-none bg-black px-10 py-6 text-md xxs:text-lg font-semibold text-white shadow-sm hover:bg-dangerlighter focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Spotify Promotion
            </a>
          </div>

          <form
            className="flex justify-start pt-20 pb-4 w-full"
            onSubmit={e => {
              e.preventDefault();
              navigate(
                `search/${(e.currentTarget[0] as HTMLInputElement).value}`
              );
            }}
          >
            <div className="w-full max-w-lg lg:max-w-xs">
              <div className="relative">
                <div className="justify-between inline-flex w-full sm:w-[400px] md:w-[600px] h-[50px] rounded-xl border-2 border-dangerlighter bg-gray-100/80 py-1.5 pr-3 !text-gray-800 placeholder:text-gray-700 focus:bg-gray-100 focus:text-gray-100 focus:ring-0 text-md sm:leading-6">
                  <input
                    id="search"
                    name="search"

                    className="bg-white/0 border-0 w-full focus:ring-0"
                    placeholder="Search beats..."
                  />
                  <div className="flex items-center pr-6">
                    <button type="submit">
                      <SearchIcon className="h-24 w-24 text-gray-800 text-right" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className="flex items-center pt-12 pb-60 gap-x-6" />


        </div>




        <div className="hidden lg:block mx-auto mt-16 max-w-xl sm:mt-24 lg:ml-10 lg:mt-0 lg:mr-0 lg:max-w-none lg:flex-none xl:ml-32">
          <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
            <img
              className="rounded-3xl pl-50 align-middle w-[700px] pt-36"
              src={logo3D}
              alt="Beatsora"

            />
          </div>

        </div>

      </div>
    </div>
  )
}

const artists1 = [
  {
    id: 1,
    name: "Chris Brown",
  },
  {
    id: 2,
    name: "DaniLeigh",
  },
  {
    id: 3,
    name: "Eric Bellinger",
  },
  {
    id: 4,
    name: "Fivio Foreign",
  },
  {
    id: 5,
    name: "Lil Baby",
  },
  {
    id: 6,
    name: "Lil Pump",
  },
  {
    id: 7,
    name: "Ty Dolla $ign",
  },
  {
    id: 8,
    name: "H.E.R.",
  },

]

const artists2 = [
  {
    id: 1,
    name: "Ed Sheeran",
  },
  {
    id: 2,
    name: "One Direction",
  },
  {
    id: 3,
    name: "Little Mix",
  },
  {
    id: 4,
    name: "Ella Henderson",
  },
  {
    id: 5,
    name: "Jacob Banks",
  },
  {
    id: 6,
    name: "Pixie Lott",
  },
  {
    id: 7,
    name: "Tinchy Stryder",
  },
  {
    id: 8,
    name: "Example",
  },

]

const companies = [
  { id: 1, logo: cineworldLogo, width: "80px", },
  { id: 2, logo: commonwealthLogo, width: "100px", },
  { id: 3, logo: eaLogo, width: "100px", },
  { id: 4, logo: fifaLogo, width: "80px", },
  { id: 5, logo: loveislandLogo, width: "80px", },
  { id: 6, logo: pepsiLogo, width: "80px", },
  { id: 7, logo: primevideoLogo, width: "80px", },
  { id: 8, logo: sonyLogo, width: "80px", },
]

const companiesFull = companies.concat(companies).concat(companies);
const artists1Full = artists1.concat(artists1);
const artists2Full = artists2.concat(artists2);

export function HomePageCompanies({ bgColour, marqueeClassname }: { bgColour: any, marqueeClassname: string }) {
  return (
    <>
      <div className={bgColour}>



        <div className="pt-24 xxl:pt-36 pb-36 text-2xl font-bold text-center text-gray-100 sm:text-3xl">Our beatmakers worked with…</div>

        <div className='pt-12' />

        <Marquee speed={20} pauseOnHover={true} direction="right" gradientColor={"28,25,23"} gradient={false} className={marqueeClassname}>
          {artists1Full.map((artist, i) => (
            <div className={""} key={i}>
              <div className='text-sm xs:text-xl md:text-2xl font-normal text-white px-8 xs:px-12 md:px-24 pb-12'>
                {artist.name}
              </div>
            </div>
          ))}
        </Marquee>

        <div className='pt-4' />

        <Marquee speed={15} pauseOnHover={true} direction="left" gradientColor={"28,25,23"} gradient={false} className={marqueeClassname}>
          {artists2Full.map((artist, i) => (
            <div className={""} key={i}>
              <div className='text-sm xs:text-xl md:text-2xl font-normal text-white px-8 xs:px-12 md:px-24 pb-12 '>
                {artist.name}
              </div>
            </div>
          ))}
        </Marquee>

        <div className='pt-1' />

        <Marquee speed={20} pauseOnHover={true} direction="right" gradientColor={"28,25,23"} gradient={false} className={marqueeClassname}>
          {companiesFull.map((company, i) => (
            <div className={"pt-6"} key={i}>
              <img

                className={"rounded-3xl align-middle px-16 xs:px-24 md:px-32 py-5 w-auto h-[24px] xs:h-[30px] md:h-[40px]"}
                src={company.logo}
              />
            </div>
          ))}
        </Marquee>






        <div className='pt-48' />

        <a href="./explore" className='mx-auto w-180 align-middle h-48 bg-danger hover:bg-dangerlighter text-white shadow-sm mt-8 block rounded-md py-4 px-3 text-center text-lg font-semibold leading-6'>
          <div className='pt-8'>
            Explore
          </div>
        </a>
        <div className="pt-48" />




      </div>
    </>
  )
}

const testimonials = [
  {
    id: 1,
    image: testimonialimage1,
    person: "Bart",
    quote: "The whole library is fire. Quality over quantity for sure!",
    job: "Superjoi",
  },
  {
    id: 2,
    image: testimonialimage2,
    person: "Noaah",
    quote: "Feeback service helps a lot!",
    job: "Rapper - 85k Monthly Listeners",
  },
  {
    id: 3,
    image: testimonialimage3,
    person: "KHG Kalm",
    quote: "Exactly what I've been looking for. Quick and easy to catch a the vibe.",
    job: "Rapper / COO of Shurikan Sounds",
  },
  {
    id: 4,
    image: testimonialimage4,
    person: "TomInTheChamber",
    quote: "Categorisation is a game changer! Highly recommended!",
    job: "Rapper/Engineer - UKF/Hospital Records",
  },
  {
    id: 5,
    image: testimonialimage5,
    person: "Shocka",
    quote: "The beats were incredible, perfect fit for what I was looking for.",
    job: "Rapper, Author, TEDx Speaker",
  },

]

const testimonialsFull = testimonials.concat(testimonials);

const importGif = (path: string) => {
  return 'https://www.beatsora.com/app' + path;
};

const beeniiceGIF = importGif('/tiktoks/compressed/beeniice.gif');
const noaah2GIF = importGif('/tiktoks/compressed/noaahmsc 2.gif');
const khgkalmGIF = importGif('/tiktoks/compressed/khgkalm.gif');
const cairosharnGIF = importGif('/tiktoks/compressed/cairosharn.gif');
const devizionGIF = importGif('/tiktoks/compressed/devizion.gif');
const noaahGIF = importGif('/tiktoks/compressed/noaahmsc.gif');
const jayjohnsonGIF = importGif('/tiktoks/compressed/jayjohnson.gif');
const beecheyGIF = importGif('/tiktoks/compressed/beechey.gif');
const arloGIF = importGif('/tiktoks/compressed/arlo.gif');
const eboniGIF = importGif('/tiktoks/compressed/eboni.gif');
const tominthechamberGIF = importGif('/tiktoks/compressed/tominthechamber.gif');
const youngnkGIF = importGif('/tiktoks/compressed/youngnk.gif');

const tiktoks = [
  {
    id: 1,
    link: "https://www.tiktok.com/@noaahmsc/video/7304354821589552416?_r=1&_t=8houmrzr1yD",
    gif: noaah2GIF,
    acc: "@noaahmsc",
    descr: "‘me 2 you freestyle’ OUT NOW - big up @Beatsora for the beat 🤞🏽 #fyp #lyrics #freestyle #ukrap #noaahmsc",
  },
  {
    id: 2,
    link: "https://www.tiktok.com/@eboni.official/video/7302578756592504097?_r=1&_t=8houw6SnFOT",
    gif: eboniGIF,
    acc: "@eboni.official",
    descr: "@Beatsora 🔥 this was such a grat vibe! 🫶🏾 If your an artist looking for dope beats head to their page! #music #beatsora #vibes #artists #singers #freestyle #rnbvibes"
  },
  {
    id: 3,
    link: "https://www.tiktok.com/@arlo4tik/video/7302136933948460321?_r=1&_t=8houzNnptlB",
    gif: arloGIF,
    acc: "@arlo4tik",
    descr: "OUT NOW, link in bio 🫶🏼 #ukrap #arlo #lyric #newmusicalert #fypシ #newmusic #beatsora @Beatsora"
  },
  {
    id: 4,
    link: "https://www.tiktok.com/@jayjohnsonofficial/video/7304306049023085857?_r=1&_t=8hougARfBQ0",
    gif: jayjohnsonGIF,
    acc: "@jayjohnsonofficial",
    descr: "Quick freestyle @Beatsora 👀 You know where to go for dope beats 👌🏾 #beatsora #beats #rappers"
  },
  {
    id: 5,
    link: "https://www.tiktok.com/@cairosharn/video/7304323818544385312?_r=1&_t=8housdqczEO",
    gif: cairosharnGIF,
    acc: "@cairosharn",
    descr: "Quick Freestyle🎙️ @Beatsora for Instrumentals💿"
  },
  {
    id: 6,
    link: "https://www.tiktok.com/@tominthechamber/video/7293571814620532000?_r=1&_t=8houvQ78a1Z",
    gif: tominthechamberGIF,
    acc: "@tominthechamber",
    descr: "fresh bars for ya headtops, beat courtesy of the ‘chill hop’ section on @Beatsora 🔥 #freestyle #freestylerap #hiphop #chillhop #chillhopbeats #beatsora #rapmusic #bars"
  },
  {
    id: 7,
    link: "https://www.tiktok.com/@beatsora/video/7304719230082108704?_r=1&_t=8hour6NRV2G",
    gif: beeniiceGIF,
    acc: "@beatsora",
    descr: "@BeeNiice Coming with the purist afro vibes!! 🔥💯 #afrobeats #wizkid #burnaboy #Beatsora"
  },
  {
    id: 8,
    link: "https://www.tiktok.com/@de.vizion/video/7302856027496647968?_r=1&_t=8houvd2ictM",
    gif: devizionGIF,
    acc: "@de.vizion",
    descr: "@Beatsora 🚀💥💥💥 #JesusChrist #NewMusic #AfroGospel #UrbanGospel #Ghanaexplore #Londonexplore #openforbookings #Freestyle #John4"
  },
  {
    id: 9,
    link: "https://www.tiktok.com/@noaahmsc/video/7302139728420162849?_r=1&_t=8houjZcNi7t",
    gif: noaahGIF,
    acc: "@noaahmsc",
    descr: "‘Me 2 You Freestyle’ OUT NOW #fyp #ukrap #freestyle #noaahmsc"
  },
  {
    id: 10,
    link: "https://www.tiktok.com/@beatsora/video/7301388679115820321?_r=1&_t=8hovICl1sK9",
    gif: youngnkGIF,
    acc: "@beatsora",
    descr: "@YoungN.K With the vibes on to this afrobeat banger! Show some love to this very talented 16-year-old 💯🔥 #afrobeats #afroswing #Beatsora #rapper #burnaboy #wizkid #beatsora"
  },
]

const tiktoksFull = tiktoks.concat(tiktoks);


function HomePageTestimonials({ bgColour }: { bgColour: any }) {
  return (
    <>
      <div className={bgColour}>
        <p className="text-3xl pt-48 pb-48 text-center mx-auto font-bold tracking-tight text-white sm:text-4xl">Check songs made with our instrumentals</p>

        <Marquee speed={50} pauseOnHover={true} direction="left" gradientColor={"28,25,23"} gradient={false}>
          {tiktoksFull.map((tiktok, i) => (
            <div className='px-20'>
              <a href={tiktok.link} target="_blank" className='w-100'>
                <img
                  className={"w-100 h-auto"}
                  src={tiktok.gif}
                />
                <div className="font-semibold text-sm w-100 truncate pt-3 text-ellipsis overflow-hidden">
                  {tiktok.acc}
                </div>
                <div className="text-xs w-100 line-clamp-3 truncate pt-3 text-ellipsis overflow-hidden">
                  {tiktok.descr}
                </div>
              </a>
            </div>
          ))}
        </Marquee>
        <div className="pt-48" />


      </div>
    </>
  )
}

const socials = [
  {
    name: 'Youtube',
    href: 'https://www.youtube.com/@Beatsora',
    icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
      <svg fill="currentColor" viewBox="0 -10 470 470" {...props}>
        <path
          fillRule="evenodd"
          d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728
          c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137
          C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607
          c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/beatsora_',
    icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'Twitter',
    href: 'https://twitter.com/Beatsora_',
    icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
      <svg fill="currentColor" viewBox="-30 -30 340 340" {...props}>
        <path d="m 236 0 h 46 l -101 115 l 118 156 h -92.6 l -72.5 -94.8 l -83 94.8 h -46 l 107 -123 l -113 -148 h 94.9 l 65.5 86.6 Z m -16.1 244 h 25.5 l -165 -218 h -27.4 Z" />
      </svg>
    ),
  },
  {
    name: 'Tiktok',
    href: 'https://www.tiktok.com/@beatsora',
    icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
      <svg fill="currentColor" viewBox="0 0 34 34" {...props}>
        <path d="M16.656 1.029c1.637-0.025 3.262-0.012 4.886-0.025 0.054 2.031 0.878 3.859 2.189 5.213l-0.002-0.002c1.411 1.271 3.247 2.095 5.271 2.235l0.028 0.002v5.036c-1.912-0.048-3.71-0.489-5.331-1.247l0.082 0.034c-0.784-0.377-1.447-0.764-2.077-1.196l0.052 0.034c-0.012 3.649 0.012 7.298-0.025 10.934-0.103 1.853-0.719 3.543-1.707 4.954l0.020-0.031c-1.652 2.366-4.328 3.919-7.371 4.011l-0.014 0c-0.123 0.006-0.268 0.009-0.414 0.009-1.73 0-3.347-0.482-4.725-1.319l0.040 0.023c-2.508-1.509-4.238-4.091-4.558-7.094l-0.004-0.041c-0.025-0.625-0.037-1.25-0.012-1.862 0.49-4.779 4.494-8.476 9.361-8.476 0.547 0 1.083 0.047 1.604 0.136l-0.056-0.008c0.025 1.849-0.050 3.699-0.050 5.548-0.423-0.153-0.911-0.242-1.42-0.242-1.868 0-3.457 1.194-4.045 2.861l-0.009 0.030c-0.133 0.427-0.21 0.918-0.21 1.426 0 0.206 0.013 0.41 0.037 0.61l-0.002-0.024c0.332 2.046 2.086 3.59 4.201 3.59 0.061 0 0.121-0.001 0.181-0.004l-0.009 0c1.463-0.044 2.733-0.831 3.451-1.994l0.010-0.018c0.267-0.372 0.45-0.822 0.511-1.311l0.001-0.014c0.125-2.237 0.075-4.461 0.087-6.698 0.012-5.036-0.012-10.060 0.025-15.083z" />
      </svg>
    ),
  },
]

function HomePageSocials({ bgColour }: { bgColour: any }) {

  return (
    <>
      <div className={bgColour}>
        <div className="mx-auto max-w-5xl pb-20 ">
          <p className="text-3xl pt-48 pb-24 text-center mx-auto font-bold tracking-tight text-white sm:text-4xl">Socials</p>
          <p className="text-lg text-gray-200 text-center max-w-xl mx-auto pb-8 px-12">
            Follow us on social media to know about any updates, new content or competitions.
          </p>
          <div className="grid grid-cols-2 gap-24 lg:grid-cols-4 max-w-lg lg:max-w-2xl mx-auto pt-30">

            {socials.map((item) => (
              <div key={item.name} className="text-center align-middle mx-auto items-center text-danger hover:text-dangerlighter">
                <a href={item.href} target="_blank" rel="noopener noreferrer" className="mx-auto">
                  <item.icon className="h-64 w-64" aria-hidden="true" />
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className='pb-48' />


      </div>

    </>
  )
}

const faqs = [
  {
    question: "Are beats royalty free?",
    answer:
      "Yes. You have a royalty-free license for every beat that you download from Beatsora.com. Please be aware that most distributors still require you to disclose the use of any licensed third party materials in your content, even if they are royalty-free. A certified license from Beatsora will be provided via email for this purpose.",
  },
  {
    question: "Where can I find my license?",
    answer:
      "Upon completion of each download, an automatic email will be sent to your registered email address containing the corresponding royalty-free license.",
  },
  {
    question: "Are the beats exclusive?",
    answer:
      <div>Beatsora's marketplace offers non-exclusive beats for all users. For exclusive beats, please visit <a className='text-danger hover:text-dangerdarker' target="_blank" href="https://www.beatsora.world/">Beatsora World</a>.</div>,
  },
  {
    question: "Why do we use credits?",
    answer:
      "When you Subscribe to Beatsora, you will recieve credits every month. These credits can be used to download beats from Beatsora."
  },
  {
    question: "Do I lose my license if I cancel my subscription?",
    answer:
      "No, the Beats you've downloaded remain covered under the license granted to you at the time of download, even if you decide to cancel or pause your Beatsora subscription.",
  },

]

function HomePageFAQ({ bgColour }: { bgColour: any }) {
  return (
    <div className={bgColour}>
      <div className="mx-auto max-w-3xl pb-48 ">
        <p className="text-3xl pt-48 pb-24 text-center mx-auto font-bold tracking-tight text-white sm:text-4xl">Why Only Exclusive Beats?</p>
        <p className="text-xl sm:text-2xl pb-24 pt-36 text-gray-200 text-center">
          An exclusive license ensures full control, unlimited use, and a distinct sound, which aligns with our company values of originality and creative ownership A lease license limits usage, distribution, and commercial rights,. It may also expire or become unavailable if sold exclusively, disrupting projects and reducing uniqueness.
        </p>
        {/* <div className="mx-auto divide-y divide-white/40 px-20">
          <dl className="mt-2 space-y-6 divide-y divide-white/40">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-20">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                        <span className="text-lg font-semibold leading-7 pb-16">{faq.question}</span>
                        <span className="ml-6 flex h-32 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-20 w-20" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-20 w-20" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="pb-8 pr-36">
                      <p className="text-base leading-7 pl-16 text-gray-300">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>


        </div> */}
      </div>


      <div className="mx-auto max-w-4xl text-center pb-16 mt-2 text-2xl font-bold tracking-tight text-white px-8">
        Can't find what you're looking for? Check out our help center.
      </div>
      <a href="/helpcentre" className='mx-auto w-180 align-middle h-48 bg-danger hover:bg-dangerlighter  text-white shadow-sm mt-8 block rounded-md py-4 px-3 text-center text-lg font-semibold leading-6'>
        <div className='pt-8'>
          Help Center
        </div>
      </a>

      <div className='pt-48' />



    </div >
  )
}

const platforms = [
  { id: 1, logo: amazon },
  { id: 2, logo: apple },
  { id: 3, logo: deezer },
  { id: 4, logo: facebook },
  { id: 5, logo: instagram },
  { id: 6, logo: pandora },
  { id: 7, logo: shazam },
  { id: 8, logo: spotify },
  { id: 9, logo: tidal },
  { id: 10, logo: tiktok },
  { id: 11, logo: twitter },
  { id: 12, logo: youtube },


]

const platformsFull = platforms.concat(platforms).concat(platforms);

function HomePagePlatforms({ bgColour }: { bgColour: any }) {
  return (
    <>
      <div className={bgColour}>



        {/*<div className="pt-48 pb-24 text-3xl font-bold text-center text-gray-100 sm:text-4xl">Don't sweat the legal</div>*/}
        <p className="text-xl sm:text-2xl pb-24 pt-36 text-gray-200 text-center">
          Every instrumental comes with an unlimited exclusive license to release your music on all platforms!  <br /> <b>Own 100% of your music, forever.</b>
        </p>


        <div className='pt-6' />
        <div className="grid grid-cols-2 gap-24 md:grid-cols-4 max-w-xl md:max-w-6xl mx-auto pt-30 px-10 items-center">

          {platforms.map((platform, i) => (
            <div className={""} key={i}>
              <img

                className={"align-middle px-26 py-5 w-auto max-h-48 mx-auto"}
                src={platform.logo}
              />
            </div>
          ))}
        </div>

        <div className="pt-48" />
        <a href="./pricing" className='mx-auto w-180 align-middle h-48 bg-danger hover:bg-dangerlighter text-white shadow-sm mt-8 block rounded-md py-4 px-3 text-center text-lg font-semibold leading-6'>
          <div className='pt-8'>
            Plans
          </div>
        </a>
        <div className="pt-48" />
        <div className='pt-48' />



      </div>
    </>
  )
}
const features1 = [
  {
    name: '100% Exclusive Instrumentals',
    description: "No Conflicts with Other Artists or Copyright Disputes",
    component: TileTextLeft,
    imageURL: tile4,
    ref: "https://www.beatsora.com/explore",
    background: "bg-gradient-to-b from-black to-danger/70",
  },
  {
    name: 'Monetize your song everywhere, without limits  ',
    description: "Every beat comes with an unlimited lease license as standard. No streaming limit, performance rights & keep 100% of your royalties!",
    component: TileTextRight,
    imageURL: tile5,
    ref: "https://www.beatsora.com/explore",
    background: "bg-gradient-to-b from-danger/70 to-black",
  },
]

const features1Old = [
  {
    name: 'No more pay per track',
    description: "Make music regularly? Subscribe to receive beats every month, AI mastering, track feedback and perks from our partners!",
    component: TileTextLeft,
    imageURL: tile4,
    ref: "https://www.beatsora.com/explore",
    background: "bg-gradient-to-b from-black to-black",
  },
  {
    name: 'Monetize your song everywhere, without limits  ',
    description: "Every beat comes with an unlimited lease license as standard. No streaming limit, performance rights & keep 100% of your royalties!",
    component: TileTextRight,
    imageURL: tile5,
    ref: "https://www.beatsora.com/explore",
    background: "bg-gradient-to-b from-black to-black",
  },
  {
    name: "AI lyric generator",
    description: "Use Beatsora AI lyric tool to cure your writers block!",
    component: TileTextLeft,
    imageURL: tile1,
    ref: "https://www.beatsora.com/ai-lyrics",
    background: "bg-gradient-to-b from-danger/70 to-black",
  },
  {
    name: 'Vocal & mixing presets',
    description: "Use our curated vocal chains to instantly enhance your vocals and make them pop in your mix!",
    component: TileTextRight,
    imageURL: tile2,
    ref: "https://www.beatsora.com/vocal-presets",
    background: "bg-gradient-to-b from-black to-black",
  },
  {
    name: 'Track feedback service',
    description: "Upload your songs to receive industry feedback from professionals in the industry.",
    component: TileTextLeft,
    imageURL: tile3,
    ref: "https://www.beatsora.com/feedback-form",
    background: "bg-gradient-to-b from-black to-blue-600/50",
  },
  {
    name: 'AI Mastering',
    description: "Master your tracks with our online mastering service!",
    component: TileTextRight,
    imageURL: tile6,
    ref: "https://www.beatsora.com/create-aimastering",
    background: "bg-gradient-to-b from-blue-600/50 to-black",
  },
]

function HomePageWhyUs({ bgColour }: { bgColour: any }) {
  return (
    <>
      <div className={bgColour}>
        <div className='pt-64' />
      </div>
      {/*<div className="pt-48 text-3xl font-bold text-center text-gray-100 sm:text-4xl">Why choose us?</div>
        <p className="pt-24 pb-24 text-lg sm:text-xl text-gray-200 text-center">
          Beatsora offers a simple process for purchasing beats quickly, without any hassle.
        </p>
        */}


      {features1.map((feature) => (
        <>
          <div className={feature.background}>
            <div className="mx-auto grid max-w-7xl grid-cols-1 gap-y-24 gap-x-8 pt-2 pb-12 px-10 lg:max-w-7xl lg:grid-cols-2 lg:px-0">
              <feature.component
                hdr={feature.name}
                dsc={feature.description}
                img={feature.imageURL}
                link={feature.ref}
              />
            </div>
          </div>
        </>
      ))}

      <div className={bgColour}>

        <div className="pt-48" />
        <a href="./pricing" className='mx-auto w-180 align-middle h-48 bg-danger hover:bg-dangerlighter text-white shadow-sm mt-8 block rounded-md py-4 px-3 text-center text-lg font-semibold leading-6'>
          <div className='pt-8'>
            See plans
          </div>
        </a>
        <div className="pt-48" />



      </div>
    </>
  )
}

const steps = [
  {
    name: '1. Subscribe',
    description: 'Pick a plan that works for you. Beat credits appear in your account every month.',
    imageSrc: explainationIcon2,
  },
  {

    name: '2. Browse',
    description: 'Browse from our catalog of beats from Grammy nominated & critically acclaimed producers.',
    imageSrc: explainationIcon1,
  },
  {
    name: '3. Download',
    description: 'Unlimited lease license included with all downloads to release your music anywhere!',
    imageSrc: explainationIcon3,
  },


]

function HomePageExplanation({ bgColour }: { bgColour: any }) {
  return (
    <div className={bgColour}>

      {/*<div className="pt-48 text-3xl font-bold text-center text-gray-100 sm:text-4xl">How does it work?</div>*/}
      {/**\
       <div className="grid grid-cols-1 max-w-xl lg:max-w-6xl mx-auto items-start gap-y-16 gap-x-10 pt-16 pb-12  sm:grid-cols-2 lg:grid-cols-5 lg:gap-x-8">


      <div className="pt-48 text-xl font-semibold text-center text-gray-100 sm:text-2xl max-w-5xl mx-auto">
      Creating music can be expensive. Between purchasing beats, recording, mixing, mastering and promotions, the cost can rack up. Welcome to Beatsora, a service that aims to make music creations more sustainable for artists!
      </div>
       */}
      <div className="flex flex-wrap justify-center max-w-xl md:max-w-4xl xxl:max-w-7xl items-start mx-auto gap-y-16 gap-x-10 pt-16 pb-12 lg:gap-x-8">
        {steps.map((step) => (
          <div key={step.name} className="flex flex-col-reverse">
            <div className="mt-6 max-w-375 px-10 mx-auto">
              <h3 className="text-md sm:text-lg font-semibold text-white pt-4 border-t border-gray-400 text-center">{step.name}</h3>
              <p className="t-2 text-md sm:text-lg text-gray-200 text-center">{step.description}</p>
            </div>
            <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg">
              <img
                className="max-w-100 h-auto mx-auto pt-32"
                src={step.imageSrc}
                alt="Beatsora"
              />
            </div>
          </div>
        ))}

      </div>


      <div className="pt-48" />
      <a href="./pricing" className='mx-auto w-180 align-middle h-48 bg-danger hover:bg-dangerlighter text-white shadow-sm mt-8 block rounded-md py-4 px-3 text-center text-lg font-semibold leading-6'>
        <div className='pt-8'>
          View Plans
        </div>
      </a>
      <div className="pt-48" />



      <div className='pt-12' />



      <div className='pt-6' />
    </div>
  )
}

const stats = [
  { id: 1, name: 'No Conflicts with Other Artists or Copyright Disputes', value: '100% Exclusive Instrumentals', icon: "M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z", },
  { id: 2, name: 'Reach real fans, no Bots or fake streams EVER!', value: 'Organic Promotion', icon: "M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0", },
  { id: 3, name: 'Supercharge Your Creative Process with AI', value: 'AI Tools ', icon: "M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z", },
]

const features2 = [
  {
    name: 'Endless inspiration',
    description: "Get notified each month when new beats are released!",
    component: TextSectionLeft,
    imageURL: '',
  },
]

function HomePageFeatures({ bgColour }: { bgColour: any }) {
  return (
    <>
      <div className={bgColour}>
        {/*
        <p className="text-3xl pt-48 pb-36 text-center mx-auto font-bold tracking-tight text-white sm:text-4xl">Why choose us?</p>
* */}
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-3xl lg:max-w-none">

            <dl className="mt-48 grid grid-cols-1 gap-2 overflow-hidden rounded-2xl text-center sm:grid-cols-1 lg:grid-cols-3">
              {stats.map((stat) => (
                <div key={stat.id} className="flex flex-col p-2 items-center justify-center">


                  <div className='text-white shadow-sm justify-center items-center block rounded-md pt-4 pb-2 px-3 text-center text-sm font-semibold leading-6 '>
                    <svg className="h-40 w-40 align-middle" aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="#ea580c" strokeWidth={1.5}>
                      <path strokeLinecap="round" strokeLinejoin="round" d={stat.icon} />
                    </svg>
                  </div>
                  <div className=''>
                    <div className="pt-2 text-2xl font-semibold tracking-tight text-gray-300">{stat.value}</div>

                    <div className="text-md pt-2 pb-4 font-semibold leading-6 text-white">{stat.name}</div>
                  </div>

                </div>
              ))}
            </dl>
          </div>

          {/**
      <div className="mx-auto grid max-w-5xl grid-cols-1 gap-y-24 gap-x-8 pt-48 pb-12 px-10 lg:max-w-5xl lg:grid-cols-2 lg:px-0 ">
        {features2.map((feature) => (
          <>
              <feature.component
              hdr = {feature.name}
              dsc = {feature.description}
              
              />


          </>
          
          ))}
        </div>
         */}

          <div className="pt-48" />
          <a href="./pricing" className='mx-auto w-180 align-middle h-48 bg-danger hover:bg-dangerlighter text-white shadow-sm mt-8 block rounded-md py-4 px-3 text-center text-lg font-semibold leading-6'>
            <div className='pt-8'>
              Plans
            </div>
          </a>
          <div className="pt-48" />


        </div>
      </div>

    </>
  )
}


function HomePageEthos({ bgColour }: { bgColour: any }) {
  return (
    <>
      <div className={bgColour}>
        {/*<div className="pt-48 text-3xl font-bold text-center text-gray-100 sm:text-4xl">Our story</div>*/}
        <div className='max-w-3xl mx-auto px-16'>
          <p className="pt-24 pb-10 text-lg sm:text-xl font-semibold text-gray-200 text-center">
            We as a company believe in supporting artists and creators by helping them on their creative journeys.
          </p>
          <p className="pt-10 text-md sm:text-lg text-gray-300 text-center">
            Our team has a background in helping creatives find their voice. From producing for young musicians at youth centres, giving music lessons, or teaching refugees how to write code. The Beatsora team has always strived to help others bring their creative vision to life. To give opportunities to those who can't afford it. We at Beatsora dream to continue supporting creativity. This is our purpose.
          </p>
          <div className="pt-48" />

          <img
            className="align-middle py-5 w-max h-auto mx-auto"
            src={ethosImage1}
          />
          <div className="pt-48" />
        </div>


      </div>
    </>
  )
}
// Sections from Mar's site - END

function HomePageConversionIntro({ bgColour }: { bgColour: any }) {
  return (
    <>
      <div className={bgColour}>
        <div className='mx-auto max-w-2xl lg:max-w-[1600px]'>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-24 gap-y-24 pb-76 pt-48 md:pt-110 px-24">
            <div className='xxl:pr-128'>
              <div className='text-3xl md:text-5xl font-semibold text-center lg:text-left'>
                Small musicians deserve great <b>instrumentals!</b>
              </div>
              <div className='text-xl md:text-2xl pt-20 font-semibold text-center lg:text-left'>
                Get 25% off your first two months using <b>code SAVE10!</b>&nbsp;<span aria-hidden="true">&rarr;</span>
              </div>
              {/**
              <div className='text-xl md:text-2xl pt-20 pb-12 font-bold text-center lg:text-left'>
                First beat is on us!
              </div>
               */}
              <div className="pt-12 flex items-center gap-x-6 mx-auto lg:mx-0 pb-36 lg:pb-0 max-w-md lg:max-w-none">
                <a
                  href="/pricing"
                  className="rounded-md max-w-[150px] xxs:max-w-none bg-danger px-10 py-6 text-lg font-semibold text-white shadow-sm hover:bg-dangerlighter focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 mx-auto lg:mx-0"
                >
                  View Plans
                </a>
              </div>
            </div>


            <div className="hidden lg:block">
              <img
                alt=""
                src={pricing}
                className="w-full h-auto object-cover object-center "
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}



function HomePageAudio({ bgColour }: { bgColour: any }) {
  return (
    <>
      <div className={bgColour}>

        <div className="pt-64 pb-36 text-2xl font-bold text-center text-gray-100 sm:text-3xl">Listen to our library</div>

        {/* Wrap the AudioPlayer component in a div with flexbox styling */}
        {/* <div className='flex justify-center items-center'>
          <AudioPlayer />
        </div> */}
        <div className="mx-auto max-w-5xl">
          <PlayerContext id="" options={playerStoreOptions}>
            <DesktopPlayerControls />
            <PlayerOverlay />
          </PlayerContext>
        </div>

        <div className='pt-28' />
        <a href="./explore" className='mx-auto w-180 align-middle h-48 bg-danger hover:bg-dangerlighter text-white shadow-sm mt-8 block rounded-md py-4 px-3 text-center text-lg font-semibold leading-6'>
          <div className='pt-8'>
            Explore
          </div>
        </a>
        <div className="pt-48" />
      </div>
    </>
  );
}