import { RadioGroup, Disclosure } from '@headlessui/react';
import { CheckIcon, ArrowDownCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid';
import { Tooltip } from 'flowbite-react';
import { useState } from 'react';
import { useProducts } from '@common/billing/pricing-table/use-products';
import { Product } from '@common/billing/product';
import { findBestPrice, UpsellBillingCycle } from '@common/billing/pricing-table/find-best-price';
import { useAuth } from '@common/auth/use-auth';
import { Trans } from '@common/i18n/trans';
import { PlanList } from '@common/billing/pricing-table/layout-pricing-table';
import StripePricingTable from '@app/landing-page/stripe-pricing-table';
import { TestPricingTable } from '@app/landing-page/test-pricing-table';

type Tier = {
    buttonText: React.ReactNode;
    name: string;
    id: string;
    href: string;
    featured: boolean;
    description: string;
    price: {
        monthly: string;
        annually: string;
    };
    yearlyCost: {
        monthly: string;
        annually: string;
    };
    yearlySavings: {
        monthly: string;
        annually: string;
    };
    preDiscountPrice: {
        monthly: string;
        annually: string;
    };
    mainFeatures: {
        monthly: string[];
        annually: string[];
    };
}

const frequencies = [
    { value: 'monthly', label: 'Monthly' },
    { value: 'annually', label: 'Annually' },
]

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}

export function TestLayoutPricing() {
    const [frequency, setFrequency] = useState(frequencies[0]);
    const query = useProducts();
    const { user } = useAuth();

    if (user && user.roles.some(role => role.name === 'Education')) {
        return (
            <div className="text-center mt-10">
                <p>This is an educational account. Please contact your provider to add more credits.</p>
            </div>
        );
    }

    return (
        <div>
            {/**
            <h1 className="text-3xl md:text-4xl text-center mt-30 md:mt-60 mb-30 font-normal md:font-medium">
                <Trans message="Choose the right plan for you" />
            </h1>
             */}
            <TestPricingTable />
        </div>
    )
}