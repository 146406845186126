import { StarIcon } from "@heroicons/react/24/solid";
import Marquee from "react-fast-marquee";
import UserIcon from "@heroicons/react/24/solid";
import { UserCircleIcon } from "@heroicons/react/24/solid";

const trustpilotReviews = [
    {
        name: "Jacob Arthur",
        stars: 5,
        title: "I gained over 8k streams on Spotify…",
        description: "I gained over 8k streams on Spotify within 7 days and my following increased from 7 to 40. Being able to expand my audience with this service to attract new listeners is extremely encouraging. Beatsora always has the artists vision and needs in mind.",
        href: "https://uk.trustpilot.com/reviews/66f91a464a7af37747e11670",
        image: "https://user-images.trustpilot.com/66f91a3d4204e5ee79a00e52/73x73.png",
        promotion: true,
    },
    {
        name: "Remz Official",
        stars: 5,
        title: "My experience",
        description: "Ive been to numerous beatsora events and every one of them has been unique, instructive and just overall helpful. Not just in the general music aspect but in music business as well. I’ve won one of their open mics with the prize being free studio time and a song released with them and it has been smooth sailing with that song.",
        href: "https://uk.trustpilot.com/reviews/6765639a09d88af3db253490",
        image: "https://user-images.trustpilot.com/676562b3682f4c363ea9510d/73x73.png",
        promotion: false,
    },
    {
        name: "Esther Clevely",
        stars: 5,
        title: "Young talent heard",
        description: "It was so great to hear the music of one of our young people from Providence House, Jess Nike, with her new EP summer wings. I’m so proud of her and grateful that support from Beatsora has meant her talent can be expressed and heard. Inspired.",
        href: "https://uk.trustpilot.com/reviews/67656b4b892e0326bf4b137b",
        image: "https://user-images.trustpilot.com/6761a85bef3b1f7b85d29f93/73x73.png",
        promotion: false,
    },
    {
        name: "Libaax",
        stars: 5,
        title: "Amazing music promotion service…",
        description: "Amazing music promotion service increased my monthly listeners by over 10k for the promo run more than I could have ever asked for! The results speak for themselves!",
        href: "https://uk.trustpilot.com/reviews/673df5eb0c5d881af448e09e",
        image: undefined,
        promotion: true,
    },
    {
        name: "Malachi",
        stars: 5,
        title: "as an artist",
        description: "as an artist, as a rapper all I want to do is create continuously and not have to waste months of my life finding a beat that I like. Beatsoras high quality Collection of beats varying through many different genres does the boring bit for you, very easy to use. might keep you up all night. Mali",
        href: "https://uk.trustpilot.com/reviews/6737facaacecd290df8f0395",
        image: "https://user-images.trustpilot.com/63efa5ffe9b29900172ccb51/73x73.png",
        promotion: false,
    },
    {
        name: "Jay Johnson",
        stars: 5,
        title: "Massively underrated platform",
        description: "I've been playing music for over 20 years and been in the music industry for over 10 years and can easily say Beatsora is a platform that's massively underrated. The user experience is seamless and the site is easy to navigate. Great platform by an experienced team who definitely know what it's like to be in the music industry.",
        href: "https://uk.trustpilot.com/reviews/6706abacc173497b9e38e297",
        image: "https://user-images.trustpilot.com/6706aba1db873cd8e4b97826/73x73.png",
        promotion: false,
    },
    {
        name: "Cameron Cragg",
        stars: 5,
        title: "Platform is legit",
        description: "Grabbed two Drill beats so far for my freestyles & all worked perfectly. Also went to their London cypher and connected with some rappers I’m still working with. This platform is legit & they defo care about rappers. Highly recommend!",
        href: "https://uk.trustpilot.com/reviews/6708f6e79f9f242dd297fd3d",
        image: undefined,
        promotion: false,
    },
    {
        name: "Arlo",
        stars: 5,
        title: "Quality",
        description: "Used one of beatsoras beats, and it was such good quality. Easy to find and the song performed great. Just hit over 50,000 streams. Would definitely recommend to up coming artits",
        href: "https://uk.trustpilot.com/reviews/672d9eac56fe37374bc1933a",
        image: "https://user-images.trustpilot.com/672d9ea6b56a505fa594e863/73x73.png",
        promotion: false,
    },
    {
        name: "Axel C",
        stars: 5,
        title: "I’m an aspiring rapper from London and…",
        description: "I’m an aspiring rapper from London and am surprised as to how much selection I get in terms of beats. It’s got so many genres available and to be fair I’m surprised by the quality of them. They’ve got an AI lyric writer as well, which I sometimes use for inspiration when I’m stuck. Game changer for me.",
        href: "https://uk.trustpilot.com/reviews/66fbd8b75d3b5e41af5dee21",
        image: undefined,
        promotion: false,
    },
    {
        name: "Noah Sutherland",
        stars: 5,
        title: "highly recommend !",
        description: "beatsora have been a good help with my music. my song ‘me 2 you’ using their beat has gained over 50,000 streams. i also attended one of their cypher events and they have a wide range of beats which you can also find on their website, they cater to every genre and are very invlusive and helpful!",
        href: "https://uk.trustpilot.com/reviews/6707d72f1d9322a61869d37c",
        image: undefined,
        promotion: true,
    },
    {
        name: "darius ledeatte-williams",
        stars: 4,
        title: "Meet these guys at there workshop for…",
        description: "Meet these guys at there workshop for an event i help set up , made my first beat and put it out on spotify loved the final result , would happliy make more.",
        href: "https://uk.trustpilot.com/reviews/66fb118ebaa414f66dbb16f1",
        image: "https://user-images.trustpilot.com/66fb1187710e8b12aa5c163a/73x73.png",
        promotion: false,
    },
    {
        name: "Jahni",
        stars: 5,
        title: "This has been a great investment",
        description: "This has been a great investment, 4,093 plus streams, 1,904 new listeners and 74 playlist Adds; all within 7 days !! I would really recommend!",
        href: "https://uk.trustpilot.com/reviews/66f8721074058643819a03a3",
        image: undefined,
        promotion: true,
    },
    {
        name: "Xavier sheikh",
        stars: 5,
        title: "I am very impressed by the variety of…",
        description: "I am very impressed by the variety of features that they include in their subscription and the different collection of beats are top quality as well.",
        href: "https://uk.trustpilot.com/reviews/67051fb9ddade85080f09d86",
        image: undefined,
        promotion: false,
    },
    {
        name: "Malacki The Monarc",
        stars: 4,
        title: "I met the guys from Beatsora at a…",
        description: "I met the guys from Beatsora at a workshop and I had a great experience. Good quality beats and they have a good service.",
        href: "https://uk.trustpilot.com/reviews/66fc109f5d5df6e356d6927c",
        image: undefined,
        promotion: false,
    },
    {
        name: "Nathan Hall",
        stars: 5,
        title: "Beatsora experience",
        description: "Came across these guys at a london rap event, the Drill Beats in the rap cypher were all fire!",
        href: "https://uk.trustpilot.com/reviews/66fc109f5d5df6e356d6927c",
        image: "https://user-images.trustpilot.com/66a10bd83397d970b7724390/73x73.png",
        promotion: false,
    },
    {
        name: "LEMZI LTD",
        stars: 4,
        title: "I’d recommend",
        description: "Beatsora have been a recent staple within my career plans. An honest way of reaching playlists and great communication when there’s been any queries, one of which was major but dealt with effectively and kept me coming back. Looking forward to seeing how they grow and continue to support artists!",
        href: "https://www.trustpilot.com/reviews/67a1dd901eb73ab94d76bd78",
        image: "https://user-images.trustpilot.com/67a1dd8a8465c5dc23ca4f74/73x73.png",
        promotion: true,
    },
    {
        name: "Israel Sotonwa",
        stars: 5,
        title: "Exceeded Expectations",
        description: "I used Beatsora to promote my artist's single, and the experience exceeded all expectations. The campaign was highly successful, but what truly stood out was the founder's genuine approach and dedication. They took the time to walk me through the entire process, offering invaluable advice that went beyond just promoting the single. It was clear the focus was on supporting my artist's success rather than Beatsora's financial gain. Their professionalism, transparency, and expertise have been truly impressive. I highly recommend Beatsora to other artists and managers.",
        href: "https://www.trustpilot.com/reviews/678a59354916d8581f327654",
        image: "https://user-images.trustpilot.com/67a1dd8a8465c5dc23ca4f74/73x73.png",
        promotion: true,
    },
    {
        name: "Ace",
        stars: 4,
        title: "Got my first 1k streams",
        description: "Got my first 1k streams, before I was only hitting 100-200 and now I broke that threshold and it’s still going up. Song name Fairytale - yxngestAce",
        href: "https://www.trustpilot.com/reviews/67a621076fe0c46067c46a59",
        image: "https://user-images.trustpilot.com/67a1dd8a8465c5dc23ca4f74/73x73.png",
        promotion: true,
    },

]

export function TestTrustpilotCarousel({ isPromotion = false }: { isPromotion?: boolean }) {

    const extendedReviews = trustpilotReviews.concat(trustpilotReviews);
    const filteredItems = isPromotion ? extendedReviews.filter(item => item.promotion === true) : extendedReviews

    return (
        <div className=" pt-48 pb-48 ">
            <p className="text-3xl pb-12 text-center mx-auto font-bold tracking-tight text-white sm:text-4xl">Reviews</p>
            <p className="text-lg text-gray-200 text-center max-w-xl mx-auto pb-24 px-12">
                See what people think of Beatsora!
            </p>
            <div className="relative left-[50%] -translate-x-[50%] w-screen">
                <Marquee speed={35} pauseOnHover={true} direction="left" gradientColor={"28,25,23"} gradient={false} className=" w-screen">
                    {filteredItems.map((review) => (
                        <div className="w-[275px] md:w-[400px] h-[187px] mx-[15px] bg-white/10 rounded-xl relative">
                            <div className="p-[14px] space-y-[3px]">
                                <div className="items-center flex justify-between ">
                                    <div className="flex items-center gap-x-6">
                                        <div className="w-16 h-16">
                                            {review.image ? (
                                                <img src={review.image} className="w-full h-full rounded-full" />
                                            ) :
                                                (
                                                    <UserCircleIcon className="text-white w-full h-full" />
                                                )}
                                        </div>
                                        <div className="text-gray-300 font-semibold line-clamp-1 text-sm">
                                            {review.name}
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-x-[5px]">
                                        <p className="text-gray-300 text-sm line-clamp-1 text-nowrap">
                                            {review.stars} / 5
                                        </p>
                                        <div className="flex gap-x-[2px]">
                                            {Array.from({ length: review.stars }, (_, index) => (
                                                <StarIcon className="text-danger h-14 w-14" />

                                            ))}
                                            {Array.from({ length: 5 - review.stars }, (_, index) => (
                                                <StarIcon className="text-white/50 h-14 w-14" />

                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className=" font-semibold text-lg line-clamp-1">
                                    {review.title}
                                </div>
                                <div className="text-gray-300 line-clamp-4 text-sm">
                                    {review.description}
                                </div>
                            </div>
                            <div className="absolute bottom-8 right-12 text-sm text-gray-300 hover:text-white cursor-pointer">
                                <a href={review.href} target="_blank" rel="noopener noreferrer">
                                    Read full review
                                </a>
                            </div>
                        </div>
                    ))}
                </Marquee>
            </div>
            <p className="text-lg text-gray-200 text-center max-w-xl mx-auto pt-16 px-12">
                Check out all of our reviews on Trustpilot:
            </p>
            <div className="pt-12 flex flex-col xss:flex-row items-center gap-x-6 mx-auto max-w-md">
                <a
                    target="_blank"
                    href="https://www.trustpilot.com/review/beatsora.com"
                    className="rounded-md max-w-[150px] xxs:max-w-none bg-danger px-20 py-12 font-semibold text-white shadow-sm hover:bg-dangerlighter focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 mb-4 xss:mb-0 mx-auto"
                >
                    Trustpilot
                </a>
            </div>
        </div>
    )
};