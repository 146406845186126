
import { Disclosure, RadioGroup } from '@headlessui/react'
import { CheckIcon, XMarkIcon, ArrowDownCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { SignalWifi1BarIcon } from '@common/icons/material/SignalWifi1Bar'
import { SignalWifi2BarIcon } from '@common/icons/material/SignalWifi2Bar'
import { SignalWifi4BarIcon } from '@common/icons/material/SignalWifi4Bar'

export const tiers = [
    {
        name: 'Local Star',
        id: 'tier-starter',
        href: 'https://billing.beatsora.com/b/bIYdUG2vXfjVbBK28d',
        featured: false,
        stream: '4k-8k',
        description: 'For beginners starting out.',
        price: "$75",
        icon: SignalWifi1BarIcon,
        mainFeatures: [
            'Get Pitched to 50+ playlists',
            'Reach a 50k+ audience',
            '2 - 4 week placement',
            'Oragnic Streams',
        ],

    },
    {
        name: 'Rising Artist',
        id: 'tier-starter',
        href: 'https://billing.beatsora.com/b/14k5oa8Ul5Jl8pyeV3',
        featured: true,
        stream: '10k-16k',
        description: 'For beginners starting out.',
        price: "$149",
        icon: SignalWifi2BarIcon,
        mainFeatures: [
            'Get Pitched to 75+ playlists',
            'Reach a 75k+ audience',
            '3 - 6 week placement',
            'Oragnic Streams',
        ],

    },
    {
        name: 'Superstar',
        id: 'tier-starter',
        href: 'https://billing.beatsora.com/b/eVa17UdaBdbNcFOfZ9',
        featured: false,
        stream: '20k-30k',
        description: 'For beginners starting out.',
        price: "$269",
        icon: SignalWifi4BarIcon,
        mainFeatures: [
            'Get Pitched to 100+ playlists',
            'Reach a 100k+ audience',
            '6 - 8 week placement',
            'Oragnic Streams',
        ],

    },
]


function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}


export function TestPromotionPackages() {


    return (
        <>

            <div>
                <div id="test-promotion-packages" className="mx-auto max-w-5xl px-6 lg:px-8">
                    <div className="relative z-0">
                        <h2 className="text-2xl lg:text-3xl font-bold tracking-tight text-white text-center mx-auto pt-28 px-10">
                            Pitch Your Music to 50+ Curated Playlists!
                        </h2>

                    </div>
                    <div className="flex justify-center items-center w-full">
                        <div className="relative mx-auto mt-28 grid max-w-2xl grid-cols-1 gap-y-8 lg:mx-0 lg:-mb-14 lg:max-w-none lg:grid-cols-3">

                            <div
                                className="hidden lg:absolute lg:inset-x-px lg:bottom-20 lg:top-20 lg:block lg:rounded-2xl lg:bg-black lg:ring-1 lg:ring-white/30"
                                aria-hidden="true"
                            />
                            {tiers.map((tier) => (
                                <div
                                    key={tier.id}
                                    className={classNames(
                                        tier.featured
                                            ? 'bg-black shadow-xl ring-1 ring-danger'
                                            : 'bg-black ring-1 ring-danger lg:bg-transparent lg:pb-14 lg:ring-0',
                                        'relative rounded-2xl'
                                    )}
                                >
                                    <div className="p-20 lg:pt-12 xl:p-10 xl:pt-36 xl:pb-72 items-center justify-items-center justify-center">
                                        <tier.icon className="h-32 w-32 mx-auto text-center text-danger mb-8 mt-8" />

                                        <h3
                                            id={tier.id}
                                            className={classNames(
                                                tier.featured ? 'text-white' : 'text-white',
                                                'text-3xl font-bold leading-6 pt-12 pb-8 mx-auto text-center'
                                            )}
                                        >
                                            <div className="inline-flex mx-auto text-center">
                                                <div>
                                                    {tier.name}
                                                </div>

                                            </div>
                                        </h3>

                                        <div className="items-stretch mx-auto text-center w-full">
                                            <div className="mt-2 items-center pb-10 mx-auto text-center">
                                                <p
                                                    className={classNames(
                                                        tier.featured ? 'text-white' : 'text-white',
                                                        'text-sm font-bold tracking-tight pb-2'
                                                    )}
                                                >
                                                    Average Streams
                                                </p>
                                                <p
                                                    className={classNames(
                                                        tier.featured ? 'text-white' : 'text-white',
                                                        'text-xl font-bold tracking-tight'
                                                    )}
                                                >
                                                    {tier.stream}
                                                </p>
                                            </div>



                                            <a href={tier.href} className={classNames(
                                                tier.featured
                                                    ? 'bg-danger shadow-sm hover:bg-dangerlighter focus-visible:outline-danger'
                                                    : 'bg-white/10 hover:bg-white/20 focus-visible:outline-white',
                                                'rounded-md py-10 px-32 text-center text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                                            )}>
                                                {/* {tier.freeTrial ? "Start free trial" : "Buy this plan"} */}
                                                Purchase For  {tier.price}
                                            </a>
                                            <div className='pt-8' />

                                        </div>
                                        <div className="mt-16 flow-root sm:mt-20">
                                            <ul
                                                role="list"
                                                className={classNames(
                                                    tier.featured
                                                        ? 'divide-white/10 border-white/10 text-white'
                                                        : 'divide-white/10 border-white/10 text-white',
                                                    '-my-2 divide-y border-t text-sm leading-6 lg:border-t-0'
                                                )}
                                            >
                                                {tier.mainFeatures.map((mainFeature) => (
                                                    <li key={mainFeature} className="flex justify-center gap-x-16 py-2 pb-72 w-280">
                                                        <CheckIcon
                                                            className={classNames(
                                                                tier.featured ? 'text-danger' : 'text-gray-500',
                                                                'h-18 w-15 flex-none'
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                        {mainFeature}
                                                    </li>
                                                ))}
                                                <div className="pt-10 lg:pt-36" />
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
                <div className='pt-48' />
                <div className='flex flex-col items-center justify-center mx-auto'>

                    <p className='text-center mx-4 font-semibold'>
                        Have any questions or need custom promotion?
                    </p>
                    <p className='text-center mx-4 pt-6 pb-12'>
                        Schedule a free 15 minute discovery call
                    </p>
                    <a
                        href="https://calendly.com/iamoutzpoken/30min"
                        target='_blank'
                        className={classNames(
                            'bg-danger shadow-sm hover:bg-dangerlighter focus-visible:outline-danger',
                            'rounded-md py-10 px-32 text-center text-md font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                        )}
                    >
                        Book a Call
                    </a>
                </div>

                <div className='pt-64' />

            </div >
        </>
    )
}