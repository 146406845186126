import { AlbumIcon } from '@common/icons/material/Album';
import { AudiotrackIcon } from '@common/icons/material/Audiotrack';
import { ExploreIcon } from '@common/icons/material/Explore';
import { NewReleasesIcon } from '@common/icons/material/NewReleases';
import { HorizontalSplitIcon } from '@common/icons/material/HorizontalSplit';
import { BuildIcon } from '@common/icons/material/Build';
import { FeedbackIcon } from '@common/icons/material/Feedback';
import { AutoAwesomeIcon } from '@common/icons/material/AutoAwesome';
import { GroupsIcon } from '@common/icons/material/Groups';
import { AutorenewIcon } from '@common/icons/material/Autorenew';
import { ShoppingCartIcon } from '@common/icons/material/ShoppingCart';
import { MenuBookIcon } from '@common/icons/material/MenuBook';
import { SellIcon } from '@common/icons/material/Sell';
import { HubIcon } from '@common/icons/material/Hub';
import { SchoolIcon } from '@common/icons/material/School';
import { MicIcon } from '@common/icons/material/Mic';
import { TextSnippetIcon } from '@common/icons/material/TextSnippet';
import { OndemandVideoIcon } from '@common/icons/material/OndemandVideo';
import { CampaignIcon } from '@common/icons/material/Campaign';

export const navigationLinks = [
  {
    id: 1,
    sidebarName: "Explore",
    navbarName: "",
    visibleInNavbar: false,
    icon: ExploreIcon,
    list: false,
    hrefSidebar: "/explore",
    hrefNavbar: null,
    highlightLinks: [],
    isNew: false,
    isPrimary: false,
    content: [],
  },
  {
    id: 2,
    sidebarName: "Beats",
    navbarName: "Explore Beats",
    visibleInNavbar: true,
    icon: AudiotrackIcon,
    list: true,
    hrefSidebar: "",
    hrefNavbar: null,
    highlightLinks: ["/explore"],
    isNew: false,
    isPrimary: true,
    content: [
      {
        id: 1,
        name: "Explore",
        icon: ExploreIcon,
        href: "/explore",
        isNew: false,
        isFree: false,
        description: "Browse our beat store for your next banger!",
        visibleInSidebar: false,
        newTab: false,
      },
      {
        id: 2,
        name: "Beat Packs",
        icon: AlbumIcon,
        href: "/channel/popular-albums",
        isNew: false,
        isFree: false,
        description: "Scope our latest beat packs!",
        visibleInSidebar: true,
        newTab: false,
      },
      {
        id: 3,
        name: "Genre's",
        icon: HorizontalSplitIcon,
        href: "/channel/collections",
        isNew: false,
        isFree: false,
        description: "Explore our curated beat genre!",
        visibleInSidebar: true,
        newTab: false,
      },
    ],
  },
  {
    id: 3,
    sidebarName: "Spotify Promotion",
    navbarName: "Spotify Promotion",
    visibleInNavbar: true,
    icon: CampaignIcon,
    list: false,
    hrefSidebar: "/promotion",
    hrefNavbar: "/promotion",
    highlightLinks: ["/promotion"],
    isNew: true,
    isPrimary: false,
    content: [],
  },

  {
    id: 4,
    sidebarName: "Artist Tools",
    navbarName: "Artist Tools",
    visibleInNavbar: true,
    icon: BuildIcon,
    list: true,
    hrefSidebar: "",
    hrefNavbar: null,
    highlightLinks: ["aimastering", "/feedback-form", "/vocal-presets", "/partners", "/ai-lyrics"],
    isNew: false,
    isPrimary: false,
    content: [
      {
        id: 1,
        name: "AI Mastering",
        icon: AutoAwesomeIcon,
        href: "/create-aimastering",
        isNew: false,
        isFree: false,
        description: "Want your beats to pop? Check our AI mastering tool to enhance your next track!",
        visibleInSidebar: true,
        newTab: false,
      },
      {
        id: 2,
        name: "AI Lyric Generator",
        icon: TextSnippetIcon,
        href: "/ai-lyrics",
        isNew: false,
        isFree: true,
        description: "Struggling to come up with lyrics? Use our AI lyric generator to find inspiration!",
        visibleInSidebar: true,
        newTab: false,
      },
      {
        id: 3,
        name: "Track Feedback",
        icon: FeedbackIcon,
        href: "/feedback-form",
        isNew: false,
        isFree: false,
        description: "Submit your tracks to get reviewed by our team of accomplished producers and musicians!",
        visibleInSidebar: true,
        newTab: false,
      },
      {
        id: 4,
        name: "Vocal Presets",
        icon: MicIcon,
        href: "/vocal-presets",
        isNew: false,
        isFree: false,
        description: "Do your vocals sound flat and boring? Download our vocal presets to sound professional!",
        visibleInSidebar: true,
        newTab: false,
      },
      {
        id: 5,
        name: "Partner Discounts",
        icon: GroupsIcon,
        href: "/partners",
        isNew: false,
        isFree: false,
        description: "Get exclusive discounts for other platforms!",
        visibleInSidebar: true,
        newTab: false,
      },
      {
        id: 6,
        name: "Artist Hub",
        icon: HubIcon,
        href: "/hub",
        isNew: false,
        isFree: false,
        description: "Read our articles to stay up-to-date with Beatsora News, and get free tutorials to improve your music!",
        visibleInSidebar: true,
        newTab: false,
      },
    ],
  },
  {
    id: 5,
    sidebarName: "Education Courses",
    navbarName: "Education Courses",
    visibleInNavbar: true,
    icon: SchoolIcon,
    list: false,
    hrefSidebar: "/education",
    hrefNavbar: "/education",
    highlightLinks: ["/education"],
    isNew: false,
    isPrimary: false,
    content: [],
  },
  {
    id: 5,
    sidebarName: "Membership",
    navbarName: "Membership",
    visibleInNavbar: true,
    icon: AutorenewIcon,
    list: false,
    hrefSidebar: "/pricing",
    hrefNavbar: "/pricing",
    highlightLinks: ["/pricing"],
    isNew: false,
    isPrimary: false,
    content: [],
  },
]